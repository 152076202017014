/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import FishboneChart from "fishbone-chart";
//components
// ui kit components
//assets
import { TooltipInfoIcon } from "assets/Iconsv2/Iconsv2";
//constant

const DetailStep2 = ({ state, functionContent, variablesObj }) => {
  const { isApproveEnabled } = variablesObj ?? {};
  const { nmDetail } = state ?? {};
  return (
    <div className="row mt-3">
      <div className="col-12 ">
        <div className="col-12 col-lg-12 col-md-12 d-flex justify-content-between px-0">
          <div className="clide-v2-title mb-1">
            <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
              {" "}
              Step 2 : Root Cause Analysis
            </label>
          </div>
        </div>
        {state.reportStatus == "progress" && isApproveEnabled ? (
          <Row>
            <Col md={5}>
              <div
                className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                style={{ width: "100%" }}
              >
                <div className="timeline-header p-0">
                  <button
                    style={{
                      backgroundColor: "darkgray",
                      color: "white",
                    }}
                    disabled={!isApproveEnabled}
                    className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                    onClick={() => {
                      functionContent.toggleStep2Fun("contributing");
                    }}
                  >
                    Contributing Factor{" "}
                  </button>
                </div>
                <div className="mx-3 my-3 px-3 pt-5">
                  <Scrollbars
                    style={{ height: "15rem" }}
                    renderView={(props) => (
                      <div
                        style={{
                          paddingRight: "15px",
                          paddingBottom: "1rem",
                          ...props.style,
                          overflowX: "hidden",
                        }}
                      />
                    )}
                  >
                    <div className="pr-4">
                      {nmDetail?.nm_reported_detail_contrubuting_factor_list?.map(
                        (el, idx) => (
                          <div className="user-badge p-3 mb-2 mr-2 w-100">
                            <div key={idx} className="clide-v2-fs-5">
                              {el.factor}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div
                className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                style={{ width: "100%" }}
              >
                <div className="timeline-header p-0">
                  <button
                    style={{
                      backgroundColor: "darkgray",
                      color: "white",
                    }}
                    className="badge border font-weight-500 px-3 py-3 rounded-pill"
                    onClick={() => {
                      functionContent.toggleStep2Fun("immediate");
                    }}
                    disabled={!isApproveEnabled}
                  >
                    Immediate Factor{" "}
                  </button>
                </div>
                <div className="mx-3 my-3 px-3 pt-5">
                  <Scrollbars
                    style={{ height: "15rem" }}
                    renderView={(props) => (
                      <div
                        style={{
                          paddingRight: "15px",
                          paddingBottom: "1rem",
                          ...props.style,
                          overflowX: "hidden",
                        }}
                      />
                    )}
                  >
                    <div
                      className="pr-4 d-flex flex-wrap "
                      style={{
                        rowGap: "0.7rem",
                        columnGap: "0.7rem",
                      }}
                    >
                      {nmDetail?.nm_reported_detail_immediate_factor_list?.map(
                        (el, idx) => (
                          <div className="user-badge p-3">
                            <div key={idx} className="clide-v2-fs-5">
                              {el.factor}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              {/* Contributing Factor  */}

              <div style={{ margin: "2rem" }}>
                {nmDetail.nm_reported_detail_contrubuting_factor_list?.length
                  ? nmDetail.nm_reported_detail_contrubuting_factor_list
                      .slice(
                        0,
                        nmDetail.nm_reported_detail_contrubuting_factor_list
                          .length / 2
                      )
                      .map((item, index) => (
                        <>
                          <Row>
                            <Col>
                              <div
                                class="vl"
                                style={{
                                  marginLeft: "2rem",
                                  borderLeft: "3px solid red",
                                  height: "2rem",
                                  borderTop: "3px solid red",
                                  width: "1rem",
                                  display: "inline-block",
                                }}
                              ></div>
                              <span
                                style={{
                                  position: "absolute",
                                  lineHeight: "1.2rem",

                                  whiteSpace: "normal",
                                }}
                                className="clide-v2-fs-5"
                              >
                                {item.factor}
                              </span>
                            </Col>
                          </Row>
                        </>
                      ))
                  : null}

                <button
                  style={{
                    background: "red",
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    fontFamily: "monospace",
                    padding: "2rem",
                  }}
                  disabled={!isApproveEnabled}
                >
                  Contributing
                  <br />
                  Factor
                </button>

                {nmDetail.nm_reported_detail_contrubuting_factor_list?.length
                  ? nmDetail.nm_reported_detail_contrubuting_factor_list
                      .slice(
                        nmDetail.nm_reported_detail_contrubuting_factor_list
                          .length / 2,
                        nmDetail.nm_reported_detail_contrubuting_factor_list
                          .length
                      )
                      .map((item, index) => (
                        <>
                          <Row>
                            <Col>
                              <div
                                class="vl"
                                style={{
                                  marginLeft: "2rem",
                                  borderLeft: "3px solid red",
                                  height: "2rem",
                                  borderBottom: "3px solid red",
                                  width: "1rem",
                                  display: "inline-block",
                                }}
                              ></div>
                              <span
                              // style={{fontSize:' 2vw !important'}}
                              style={{
                                position: "absolute",
                                lineHeight: "1.2rem",

                                whiteSpace: "normal",
                              }}
                               className="clide-v2-fs-5"
                              >
                                {item.factor}
                              </span>
                            </Col>
                          </Row>
                        </>
                      ))
                  : null}
              </div>
            </Col>

            <Col>
              {/* Immediate Factor  */}

              {nmDetail.nm_reported_detail_immediate_factor_list?.length
                ? nmDetail.nm_reported_detail_immediate_factor_list
                    .slice(
                      0,
                      nmDetail.nm_reported_detail_immediate_factor_list.length /
                        2
                    )
                    .map((item, index) => (
                      <>
                        <Row>
                          <Col>
                            <div
                              class="vl"
                              style={{
                                marginLeft: "2rem",
                                borderLeft: "3px solid #ff4d6c",
                                height: "2rem",
                                borderTop: "3px solid red",
                                width: "1rem",
                                display: "inline-block",
                              }}
                            ></div>
                            <span
                              style={{
                                position: "absolute",
                                lineHeight: "1.2rem",

                                whiteSpace: "normal",
                              }}
                               className="clide-v2-fs-5"
                            >
                              {item.factor}
                            </span>
                          </Col>
                        </Row>
                      </>
                    ))
                : null}

              <div style={{ margin: "2rem" }}>
                <button
                  style={{
                    background: "#ff4d6c",
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    fontFamily: "monospace",
                    padding: "2rem",
                  }}
                >
                  Immediate
                  <br />
                  Factor
                </button>
              </div>

              {nmDetail.nm_reported_detail_immediate_factor_list?.length
                ? nmDetail.nm_reported_detail_immediate_factor_list
                    .slice(
                      nmDetail.nm_reported_detail_immediate_factor_list.length /
                        2,
                      nmDetail.nm_reported_detail_immediate_factor_list.length
                    )
                    .map((item, index) => (
                      <>
                        <Row>
                          <Col>
                            <div
                              class="vl"
                              style={{
                                marginLeft: "2rem",
                                borderLeft: "3px solid #ff4d6c",
                                height: "2rem",
                                borderBottom: "3px solid red",
                                width: "1rem",
                                display: "inline-block",
                              }}
                            ></div>
                            <span className="clide-v2-fs-5">{item.factor}</span>
                          </Col>
                        </Row>
                      </>
                    ))
                : null}
            </Col>
            <Col>
              {/* Root Cause Analysis  */}

              <div style={{ margin: "2rem" }}></div>
            </Col>
          </Row>
        )}
        <Row className="mt-5">
          <Col>
            <div className="justify-content-center d-flex fishbon-chart-wrapper ">
              <div
                className="horizontal-timeline-wrapper shadow-none border  mb-4 "
                style={{ width: "100%" }}
              >
                <div className="timeline-header p-0">
                  <button
                    style={{
                      backgroundColor: "darkgray",
                      color: "white",
                    }}
                    className="badge  border font-weight-500 px-3 py-3 rounded-pill"
                    disabled={!isApproveEnabled}
                    onClick={() => {
                      if (state.reportStatus == "progress") {
                        functionContent.toggleStep2Fun("root cause");
                      }
                    }}
                  >
                    {state.reportStatus == "progress"
                      ? "Add Root Cause Analysis"
                      : "Root Cause Analysis"}
                  </button>
                </div>
                <div className="mx-3 my-3 px-3 pt-5">
                  <div className="d-flex-center overflow-auto py-4">
                    {/* Root Cause<br/>Analysis */}
                    <FishboneChart data={state.FishData} />
                    <div className="ml-2 ">
                      <ReactTooltip
                        effect="solid"
                        place="right"
                        id={`Fishbone`}
                      >
                        <>
                          <div className="output-text font-16 font-weight-500 ">
                            Root Cause Analysis
                          </div>
                        </>
                      </ReactTooltip>
                      <TooltipInfoIcon data-tip data-for={`Fishbone`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DetailStep2;
