import React, { Component } from "react";
import LoginForm from "./../Login/LoginForm";
import ForgotPassword from "./../Forget/ForgotPassword";
import ResetPassword from "./../Reset/ResetPassword";
import { Switch, Route } from "react-router-dom";
import Url from "app/shared/constants/Url";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="inner-text">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default class AuthRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: "",
      tagLine: {},
    };
  }

  componentDidMount = () => {
    this._getLogo();
    this._getTagline();
  };

  _getLogo = async () => {
    const requestData = {
      status: 1,
      link: Url.clientURL,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.compnayLogo,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ logoUrl: response.data.data.logo });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  _getTagline = async () => {
    let { hostname, protocol } = window.location;
    const requestData = {
      status: 1,
      link: `${protocol}//${hostname}`,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.tagLine,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ tagLine: response.data.data });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  render() {
    return (
      <>
        <div className="sign-column s1">
          <div className="sign-column-face s2">
            <div className="s3">
             
              <div className="d-flex " >
                <div className="brand-logo">
                  <img
                    className="logo-img"
                    src={this.state.logoUrl}
                    alt="logo"
                  />
                </div>
                <div>
                  <div
                    className="client-para overflow-auto"
                    style={{
                      maxHeight: "6rem",
                      textAlign: "left",
                      padding: "4% 0",
                    }}
                  >
                    <h4 className="clide-v2-primary-color">Demo Company pvt ltd</h4>
                    <p>Zero Harm - Zero Injury - Zero Emission</p>
                  </div>
                </div>
              </div>

              <Switch>
                <Route path="/auth/login">
                  <LoginForm />
                </Route>
                <Route path="/auth/forget-password">
                  <ForgotPassword />
                </Route>
                <Route path="/auth/set-password/:token/:flag">
                  <ResetPassword />
                </Route>
                <Route path="/sid">
                  <h1>Auth</h1>
                </Route>
                <Route path="/sid">
                  <h1>Auth</h1>
                </Route>

                <Route path="*" exact={true} component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </>
    );
  }
}
