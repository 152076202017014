import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { connect } from "react-redux";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";
import CommonDropdown from "../ReportModal/CommonDropdown";
import axios from "axios";
import { SuccessToast } from "app/shared/components/Popups/ToastMessage";
import { withRouter } from "react-router-dom";
import {
  UAUC,
  NM,
  IRA,
  KPI,
  OVERVIEW,
  COMPLIANCE_FORM,
  SUMMARY,
  TOOL_BOX_TALK,
  GOOD_PRACTICES,
  TRAINING,
  POLICY_MANAGEMENT,
  E_LEARN,
  GOAL,
  DOS_AND_DONTS,
  MSDS,
  STANDARD,
  PSSR,
  BBS,
  MOCK_DRILL,
  CONTRACTOR_ASSESMENT,
  FEEDBACK,
  SKILL_UP,
  ASSESSMENT,
  SAFE_MAN_HOURS,
  TASK_MANAGEMENT,
  SAFETY_REVIEW_MEETING,
  POLL,
  SURVEY,
  AUDIT,
  INSPECTION,
  CHECKLIST,
  WORK_PERMIT,
  COMPARATIVE_ANALYSIS,
  FINANCIAL_ANALYSIS,
  MAPS_VIEW,
  H_TRIANGLE,

} from "app/shared/constants/ModuleNames";
import { ArrowDownIcon } from "assets/Iconsv2/Iconsv2";


const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class FormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList: [
        { type_id: 1, form_type: "Data Collection", module: COMPLIANCE_FORM }, 
        { type_id: 2, form_type: "Audit", module: AUDIT }, 
        { type_id: 3, form_type: "Inspection", module: INSPECTION }, 
        { type_id: 4, form_type: "Checklist", module: CHECKLIST }, 
        { type_id: 5, form_type: "Work Permit", module: WORK_PERMIT }, 
        { type_id: 6, form_type: "PSSR", module: PSSR }, 
        { type_id: 7, form_type: "BBS", module: BBS },
         { type_id: 8, form_type: "Survey", module: SURVEY },
          { type_id: 9, form_type: "Mock Drill", module: "Mock_Drill" }, 
          { type_id: 10, form_type: "Contractor Assessment", module: CONTRACTOR_ASSESMENT }, 
          { type_id: 11, form_type: "Feedback", module: FEEDBACK }, 
          { type_id: 12, form_type: "E-Learning", module: E_LEARN }, 
          { type_id: 13, form_type: "Assessment", module: ASSESSMENT }
        ],
      selectedFormType: 0,
    };
  }

  componentDidMount = async () => {

    this.permitedModules();
  };

  permitedModules = async () => {
    let temp = await this.state.typeList.filter((data, index) => this._handlePermittedRoute(data.module))
    this.setState({ typeList: temp })
  }
  _handlePermittedRoute = (checkRoute) =>
    checkPermittedRoute(this.props.permittedModuleList, checkRoute);

  _uploadDocument = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    await this.setState({ dataLoading: true });

    this._setDataLoading(true);

    let uploadData = new FormData();
    uploadData.append("auth_key", token);
    uploadData.append("user_id", userId);
    uploadData.append("document_name", this.state.documentName || '');
    uploadData.append("type", this.state.selectedType || 0);
    uploadData.append("file", this.state.selectedFile || '');

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let URL = Url.tbt.addDocument;

    await axios
      .post(URL, uploadData, ContentType)
      .then(async (response) => {
        this._setDataLoading(false);
        SuccessToast("Document Uploaded Successfully!");
        this.props.handleClose();
      })
      .catch((error) => {
        this._setDataLoading(false);
        SuccessToast("Document can not be Uploaded. Try Again!");

        console.log(error);
      });
  }

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _typeChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedType } = prevState;
      selectedType = id;
      return { selectedType };
    });
    let route = "/create-form/" + id + "/0";
    this.props.history.push(route);
    this.props.handleClose();
  };

  _formTypeMapping =
    () =>
      this.state.typeList?.map((el, idx) => (
        <li key={idx} onClick={() => this._typeChangeHandle(el.type_id)}>
          <div className="dropdown-item border-bottom ">{el?.form_type}</div>
        </li>
      ))

  handleMouseOver = (e) => {
    e.target.style.backgroundColor = "grey";
  };

  handleMouseOut = (e) => {
    e.target.style.backgroundColor = "white";
  };

  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="documentPopup"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Form Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>
            {/* <CommonDropdown
              // title="Do's & Dont's / Training / Policy"
              title="Form Type"
              isRequired={false}
              optionList={this.state.typeList}
              onChangeHandle={this._typeChangeHandle}
              value={this.state.selectedType}
            /> */}
            <div className="nav-item dropdown">
              <a
                className="nav-link form-select-dropdown dropdown-toggle d-flex justify-content-between align-items-center"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <button className="clide-v2-i-btn clide-v2-mob-hide"> */}
                  <span>Select Form Type</span>
                  <div className="clide-v2-profile-arrow-icon">
                    <ArrowDownIcon />
                  </div>
                {/* </button> */}
              </a>
              <ul className="dropdown-menu w-100">
                <div
                  style={{ margin: "0.25rem 0rem" }}
                  onMouseOver={this.handleMouseOver}
                  onMouseOut={this.handleMouseOut}
                >
                  {this._formTypeMapping()}
                </div>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(FormModal));
