import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useState } from "react";
import { CrossBlack } from "assets/Iconsv2/Iconsv2";
import ClideLoader from "app/shared/ui-kit/loader";
import { useHistory, Link } from "react-router-dom";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
const qs = require("qs");


function CommNotify(props){
    const [commnotificationList, setCommNotificationList] = useState([]);
    const [dataLoading, setDataloading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [commnotificationCount, setCommNotificationCount] = useState(0);
    const [commnotificationNumber, setCommNotificationNumber] = useState(0);
    const history = useHistory();





    useEffect(() => {
        _getCommNotificationData();
      }, []);

      const _getCommNotificationData = async () => {
        setDataloading(true);
        let userId = localStorage.getItem("userId");
        let token = localStorage.getItem("token");
    
        let requestData = {
          auth_key: token,
          user_id: userId,
          page_no: pageNumber,
        };
    
        let URL = Url.communicationNotification;
    
        let ContentType = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
    
        await axios
          .post(URL, qs.stringify(requestData), ContentType)
          .then(async (response) => {
            if (response.status == 200) {
              setCommNotificationCount(response.data.lst_count);
              setCommNotificationList(
                pageNumber > 1
                  ? [...commnotificationList, ...response.data]
                  : response.data
              );
              setPageNumber(pageNumber + 1);
            }
          })
          .finally((error) => {
            setDataloading(false);
          });
      };
    

    return(
        
      <div className="notification-dropdown">
      <div className="align-items-center d-flex justify-content-between pr-3 w-100 border-bottom">
        <div className="align-items-center d-flex">
          <img
            src={require("assets/sls-icons/Notifications.svg")}
            style={{
              // marginRight: "10px",
              height: "1.4vw",
              width: "1.4vw",
              margin: "1.5vw 0 1.5vw 1.5vw",
            }}
          />
          <h2 className="clide-v2-fs-3 m-3">Communication Notification</h2>
        </div>
        <div>
          <button className="clide-v2-icon-btn" onClick={props.handleClose}>
            <CrossBlack />
          </button>
        </div>
      </div>
    {dataLoading && !commnotificationList.length ? (
          <div className="my-3">
            <ClideLoader />
          </div>
        ) : (
        <Scrollbars
          style={{ height: "32vw"}}
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              className="track-horizontal"
              style={{ display: "none" }}
            />
          )}
          renderThumbHorizontal={(props) => (
            <div
              {...props}
              className="thumb-horizontal"
              style={{ display: "none" }}
            />
          )}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
          onScrollStop={
            commnotificationList.length !=commnotificationCount &&
            _getCommNotificationData
          }
        >
          {commnotificationList.length ? (
            commnotificationList.map((item, index) => (
              <>
               <Link
                    className="text-decoration-none clide-v2-secondary-color"
                  >
                  <div
                    key={`communicationNotification-${index}`}
                    className="notification-box p-2"
                    style={{
                      margin:"0.5vw 1vw"
                    }}
                  >
                    <Toast className="w-100" >
                      <div className="pt-1">
                        <div className="d-flex space-between px-3 align-items-center"  
                        style={{paddingTop:"0.5vw"}}
                        >
                          <div className="d-flex mb-auto">
                            <img
                              src={item.icon}
                              style={{
                                height: "2vw",
                                width: "2vw",
                                borderRadius: "50%",
                              }}
                              alt="user-profile"
                            />
                            <div>
                              <h6 className="text-overflow-ellipsis ml-3 mb-0">
                                {item.recentUpdate_name}
                              </h6>
                              <Toast.Body className="px-3"
                              style={{
                                padding:"0.3vw 0.3vw 1vw"
                              }}
                              >{item.recentUpdate_description}</Toast.Body>
                            </div>
                            </div>

                            <div className="badge border clide-v2-fs-5 clide-v2-secondary-color mb-auto px-2">
                            <p className="mb-0">{item.publish_date}</p>
                          </div>
                        </div>
                      </div>
                    </Toast>
                  </div>
                  </ Link>
              </>
            ))
          ) : (
            <div>No new notification</div>
          )}
        </Scrollbars>
        )}
    </div>
    );
}
export default CommNotify;
