/**
@description : Checking value is valid or not
*/

import axios from "axios";
const qs = require("qs");

const isValid = (value) => {
  if (
    value !== undefined &&
    value !== "" &&
    value !== null &&
    value.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

const sharedAxiosAPI = (
  URL = null,
  ContentType = { "Content-Type": "application/x-www-form-urlencoded" },
  data = {},
  qsArrayFormat = null
) => {
  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");

  var requestData = {
    auth_key: token,
    user_id: userId,
    ...data,
  };

  return axios.post(URL, qs.stringify(requestData, qsArrayFormat), {
    headers: ContentType,
  });
};

const checkPermittedRoute = (routeList, checkingRoute) => {
  const searchModule = checkingRoute.toLowerCase().trim();
  // console.log("searchModule === " +searchModule);
  // console.log("routeList ==== "+routeList);
  const find = routeList.find(
    (listItem) => listItem.module_name.trim().toLowerCase() == searchModule
  );
  return find == undefined ? false : true;
};

const urlBuilder = (str = "") => {
  var re = new RegExp("^(http|https)://", "i");
  if (re.test(str)) {
    return str;
  }
  return `https://${str}`
}

export { isValid, sharedAxiosAPI, checkPermittedRoute, urlBuilder };
