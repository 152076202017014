import React from "react";
import { withRouter } from "react-router-dom";

const Backbutton = (props) => {
  const _handleBack = () => {
    console.log("back pressed");
    props.history.goBack();
  };

  return (
    <button
      type="button"
      className="clide-v2-icon-btn mr-2"
      onClick={_handleBack}
      title=" << Go Back"
    >
      <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={16} cy={16} r="15.5" fill="white" stroke="#F56620" />
        <path
          d="M11.825 17L17.425 22.6L16 24L8 16L16 8L17.425 9.4L11.825 15H24V17H11.825Z"
          fill="#F56620"
        />
      </svg>
    </button>
  );
};

export default withRouter(Backbutton);
