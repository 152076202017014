import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import ClideLoader from "app/shared/ui-kit/loader";

const AIModal = (props) => {
  const [dataLoading, setDataloading] = useState(false);
  return (
    <div>
      <Modal.Header closeButton style={{ alignItems: "center" }}>
        <Modal.Title style={{ textAlign: "center", flex: "auto" }}>
          {/* AI {props.aiStatus ? "Approved" : "Rejection"} Report */}
          AI Verification Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pr-0">
        {dataLoading ? (
          <div className="my-3">
            <ClideLoader />
          </div>
        ) : (
          <Scrollbars
            style={{ height: "32vw" }}
            renderTrackHorizontal={(props) => (
              <div
                {...props}
                className="track-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderThumbHorizontal={(props) => (
              <div
                {...props}
                className="thumb-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderView={(props) => (
              <div
                style={{
                  paddingRight: "15px",
                  ...props.style,
                  overflowX: "hidden",
                }}
              />
            )}
          >
            <div className="text-center m-2 p-2">
              <div className="clide-v2-white-box p-3 m-2">
                <img src={props.aiData.aiImage}></img>
                <div className="mt-3">
                  {Object.entries(props.aiData.aiResponse || {}).map(([key, value]) => (
                    <p key={key}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
                      <span style={{ color: value ? "green" : "red" }}>
                        {value ? "Detected" : "Not Detected"}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </Modal.Body>
    </div>
  );
};

export default AIModal;