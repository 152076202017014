/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//assets
//assets
import {
  RoundDelete,
  RoundEdit,
  TeamAdd,
} from "assets/Iconsv2/Iconsv2";
const DetailTimelineSection = ({
  data = [],
  originFlag = "",
  state,
  variablesObj,
  functionContent,
}) => {
  const { nmDetail = {}, soiId = "" } = state ?? {};
  const _handleBackButtonAction = () => {
    if (originFlag == "kanban") {
      return "/nm/kanban";
    } else {
      return "/";
    }
  };
  const { isApproveEnabled } = variablesObj;

  return (
    <div className="row  clide-v2-colume_title ">
      <div className="mb-1 clide-v2-fs-3 fw-bold">NM Detail</div>
      <div className="row my-4 align-items-center">
        <div className="col-12 col-lg-4 col-md-4 px-0">
          <Link to={_handleBackButtonAction()}>
            <Button
              className="border clide-v2-primary clide-v2-white-color"
              variant=""
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </Button>
          </Link>
        </div>
        <div className="col-12 col-lg-4 col-md-4">
          <div>
            <span className="common-black-1 font-weight-500">
              {" "}
              {soiId ?? ""}{" "}
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {" "}
                {nmDetail.type_of_act == "condition" ? "UC" : "UA"} -{" "}
                {nmDetail.activity}{" "}
              </span>
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {nmDetail?.reportedmonth_name}
              </span>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-end px-0">
          <div className="d-flex align-items-center">
            { ["pending", "progress"].includes(state.reportStatus)? (
              <>
                {nmDetail.edit_flag == 1 && (
                  <Link
                    to={`/edit-nm-reporting-detail/${nmDetail.nm_id}/${nmDetail.service_id}/${nmDetail.project_id}/edit`}
                  >
                    <button className="clide-v2-icon-btn mr-2" title="Edit">
                      <RoundEdit />
                    </button>
                  </Link>
                )}

                {nmDetail.delete_flag == 1 ? (
                  <div>
                    <button
                      className="clide-v2-icon-btn mr-2"
                      title="Delete"
                      onClick={() => functionContent.showModal()}
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </>
            ) : null}

            {state.reportStatus == "completed" ? (
              <div className="rd-btn right-block-gap ">
                <Link to={`/nm-report/${nmDetail.nm_id}`} target="_blank">
                  <img
                    src={require("assets/sls-icons/V2. New Development/PDF.svg")}
                    className="clide-v2-profile-img clide-v2-pdf-icon"
                    alt="completed pdf"
                  />
                </Link>
              </div>
            ) : null}
            <div>
              {state.resolveFlag == "resolve" ? (
                <span className="common-black-1 font-weight-500">
                  {" "}
                  Resolved{" "}
                </span>
              ) : null}

              {["pending", "progress"].includes(state.reportStatus) &&
              isApproveEnabled ? (
                <button
                  className="clide-v2-icon-btn mr-2"
                  title="Add Team"
                  onClick={functionContent.openInviteUser}
                >
                  <TeamAdd />
                </button>
              ) : null}
              <div className="image-box">
                {nmDetail.invited_user?.map((image, index) => (
                  <img
                    style={{ marginRight: "0.5vw" }}
                    src={image.userpic}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pe-3 clide-v2-p-0">
        <div className="clide-v2-card-box-1 p-3">
          <div className="text-center clide-v2-primary-color py-4">
            Timeline
          </div>
          <div className="overflow-x overflow-auto">
            <div
              className="line_box stav_projektu"
              style={{ margin: "40px 0 40px 0" }}
            >
              {data?.map((el) => {
                return (
                  <div className="text_circle done ">
                    <a href="#" className="tvar">
                      <span />
                    </a>
                    <div className="circle clide-v2-white-box ">
                      {el?.title}
                      <hr />
                      {el?.cardTitle}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailTimelineSection);
