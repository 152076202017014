/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Col, Modal, ProgressBar } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Tree, TreeNode } from "react-organizational-chart";
import { RefreshCw, X, Book, Eye } from "react-feather";
import {
  convertDateFromTimezone,
  toDateByFormat,
} from "app/shared/utility/tzUtilFunction";

import Url from "app/shared/constants/Url";
import axios from "axios";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { Scrollbars } from "react-custom-scrollbars";
import * as Highcharts from "highcharts";
import HC_timeLine from "highcharts/modules/timeline";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
// ui kit components
import {
  Combobox,
  Textarea,
  Checkbox,
  Icon,
  Modal as SLDModal,
  Button as SLDButton,
  Popover as SLDPopover,
} from "@salesforce/design-system-react";
import makeAnimated from "react-select/animated";
// utils
import { searchInArrary } from "app/views/Form/SubmitFormListing/FormApprovalTableView";
import { getNextDays } from "app/views/Nm/Detail";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";

//components
import ClideLoader from "app/shared/ui-kit/loader";
import DetailTimelineSection from "./components/detailPage/timelineSection";
import DetailLeftSection from "./components/detailPage/leftSection";
import DetailRightSection from "./components/detailPage/rightSection";
import { InviteUser } from "app/shared/components/Popups";
//constants
import {
  iraConstant,
  refreshUrls,
  _getAddedInjuredPersonName,
  nestedListParser,
  intialValue,
  apiUrl,
  StyledNode,
  toDataURL,
  analysisContent,
  analysisListType,
  analysisType,
  postUrl,
  YearSelect,
  month,
} from "./constants/constants";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
  TooltipInfoIcon,
  FilterRound,
} from "assets/Iconsv2/Iconsv2";

HC_timeLine(Highcharts);
const qs = require("qs");

const animatedComponents = makeAnimated();
export const _momentDate = (stringDate) =>
  moment(stringDate, "DD-MM-YYYY").toDate();

class IRADetail extends Component {
  constructor(props) {
    super(props);
    this.state = iraConstant.state.details(this.props ?? {});

    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
    this.selectorRef = React.createRef(null);
  }

  componentDidMount() {
    this.CommentList();
    // this.reportDetail();
    this.UserWhoLiked();
    this.iraDetails();
    this.Dasycount();
    this.fetchReturningList();
    if (this.state.reportStatus === "progress") {
      this.fetchActionMaster();
      this.fetchInfoMaster();
      this.fetchInjuredParts();
      this.fetchWorkingShift();
      this.fetchIncidentQuestions();
      this.fetchIdentityProof();
      this.fetchIraIncidentTime();
      this.fetchWorkType();
      this.fetchRootCauseParameters();

      this.fetchGenderList();
      this.fetchInjuryTypeList();
    }
  }

  componentDidUpdate(prevProps) {
    const buttons = document.querySelectorAll(".cause");
    buttons.forEach((button) => {
      button.addEventListener("click", this.manage5mAnalytics);
    });
  }

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  _fetchProjectListFlag = async () => {
    let requestData = {
      service_id: this.state.iraBasicInfo.service_id,
      module_name: "ira",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      ira_id: this.state.iraId,
      team_member_id: selectedUserId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/removeteammembers", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            message: "User removed successfully",
            type: "success",
          });
          window.location.reload(false);
          //       this.reportDetail();
        }
      });

    // await axios
    //   .post(
    //     Url.smDashboard.removeInviteUser,
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //       report_id: this.state.soiId,
    //       selected_user_id: selectedUserId,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.status == 200) {
    //       AlertMessage({
    //         message: "User removed successfully",
    //         type: "success",
    //       });
    //       this.reportDetail();
    //     } else {
    //       AlertMessage({
    //         message: "User not removed. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {});
  };

  CommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
          limit: 99999,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
        }
      })
      .catch((error) => {});
  };

  subCommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ subcommentlist: response.data.data, comment: 1 });
        }
      })
      .catch((error) => {});
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.iraId,
            module: "ira",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "" });
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "" });
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var reportData = this.state.iraDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: reportData.service_id,
          project_id: reportData.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (iraId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        //Url.soiDashboard.soiopendelete,
        Url.iraModule.deleteIRAReport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "IRA Deleted Successfully",
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: "IRA Delete Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  submitTargetDate() {
    var targetDate = this.state.selectedTargetDate;
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const ira_reported_id = this.state.iraId;

    let data = {
      auth_key: token,
      user_id: userId,
      ira_id: ira_reported_id,
      target_date: moment(targetDate).format("YYYY-MM-DD"),
      service_id: service_id,
      project_id: project_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/iratargetdateupdate", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        // console.warn(responseJson)
        if (responseJson.status == 200) {
          AlertMessage({
            message: "Target date updated successfully",
            type: "success",
          });
          this.setState({
            afterTargetDatePopup: this.state.reportStatus === "pending",
            teamLeadID: null,
            confirmPopup: false,
          });
          this.iraDetails();
        } else {
          AlertMessage({ message: "unable to set target date", type: "error" });
        }
      });
  }

  confirmSubmitTargetDate() {
    this.setState({ confirmPopup: true });
  }

  cancleSubmitTargetTime() {
    this.setState({ selectedTargetDate: null });
    // console.log("heheh")
  }

  _handleChange = async (item = null, type = "observation") => {
    if (item != null && type == "target_date") {
      this.setState({ selectedTargetDate: item });
    }
  };

  _AddTeamLeader = async (user_id) => {
    this.setState({
      dataLoading: true,
    });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const ira_reported_id = this.state.iraId;
    const team_member_id = user_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      ira_id: ira_reported_id,
      team_member_id: team_member_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/createteamleader", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        //window.location.reload(false);
        this.setState({
          dataLoading: false,
        });
        if (responseJson.status == 200) {
          AlertMessage({
            message: "Team Leader selected successfully",
            type: "success",
          });
          this.setState({ teamLeadID: user_id, dataLoading: false });
          await this.setState((prevState) => {
            let { people_involved_list } = prevState?.iraBasicInfo;
            people_involved_list = people_involved_list?.filter(
              (team) => team.user_id !== user_id
            );
            return {
              iraBasicInfo: {
                ...prevState?.iraBasicInfo,
                people_involved_list,
              },
            };
          });
          // this._iraDetails();
        }
      });
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.iraId;
    //console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.team_member_id));
    console.log(newUsers);
    console.log("sayan 2 " + selectedUserIds);

    var data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      ira_id: reportId,
      team_members: selectedUserIds,
      status: 0,
    };

    console.log("https://dev-api.clideoffice.com/listira/addteammembers");

    fetch(Url.clientURL + "/listira/addteammembers", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({ message: "Team added successfully", type: "success" });
          window.location.reload(false);
          // this.reportDetail();
        }
      });

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);

    //   if (property == "selected_user_id") {
    //     data[property].map((item, key) =>
    //       formBody.push(encodedKey + "=" + item)
    //     );
    //   } else {
    //     formBody.push(encodedKey + "=" + encodedValue);
    //   }
    // }
    // formBody = formBody.join("&");
    // // console.log(formBody)

    // await axios
    //   .post(Url.clientURL+"/listira/addteammembers", formBody, {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   })

    //   .then((response) => {
    //     console.log("sayan resp")
    //     if (response.data.status == 200) {
    //       console.log("sayan 200")
    //       AlertMessage({ message: "User added successfully", type: "success" });
    //       this.reportDetail();
    //     } else {
    //       AlertMessage({
    //         message: "User not added. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("sayan err")

    //   });
  };

  _toggleDataLoading = async (flag) => {
    await this.setState({ dataLoading: flag });
  };

  resetFactorsState = (callback = {}) => {
    this.setState(
      {
        cflist: [],
        Iflist: [],
        rcalist: [],
      },
      callback
    );
  };

  toggleStep2Fun(type = "none") {
    let activeLabel = type;
    if (type == "immediate") {
      activeLabel = "Immediate Factor";
    } else if (type == "root cause") {
      activeLabel = "Root Cause Analysis";
    } else if (type == "contributing") {
      activeLabel = "Contributing Factor";
    }

    this.setState({ step2Type: activeLabel }, () => {
      if (this.state.toggleStep2) {
        this.setState({ toggleStep2: false });
      } else {
        this.setState({ toggleStep2: true });
      }
    });
  }

  _InProgressContributingList = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/contributingfactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 CFlist");
          await this.setState({ CFList: responseJson.data });
          await this.setState((prevState) => {
            let CFList = { ...prevState.CFList };
            CFList.factors_list = CFList.factors_list.map((item) => ({
              ...item,
              isChecked: false,
            }));
            return { CFList };
          });
        }
      });
  };

  _InProgressImmediateList = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/rootcause/immediatefactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200 IFlist");
          this.setState({ IFList: responseJson.data });
          this.setState((prevState) => {
            let IFList = { ...prevState.IFList };
            IFList.factors_list = IFList.factors_list.map((item) => ({
              ...item,
              isChecked: false,
            }));
            return { IFList };
          });
        }
      });
  };

  _InProgressRcaList = async () => {
    // console.log("sayan fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const iraID = this.state.iraId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: iraID,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/rootcause/rcafactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("RCAF 200 sayan");
          this.setState({ RCAFList: responseJson.data });
          this.setState((prevState) => {
            let RCAFList = { ...prevState.RCAFList };
            RCAFList.factors_list = RCAFList.factors_list.map((item) => ({
              ...item,
              isChecked: false,
            }));
            return { RCAFList };
          });
        }
      });
  };

  setFishData() {
    const RawFishdata =
      this.state.iraDetailStep4.ira_reported_detail_RCA_factor_list;
    console.log("sayan RCA ", RawFishdata);
    const filtered =
      this.state.iraDetailStep4.ira_reported_detail_RCA_factor_list.map(
        (el) => el.factor
      );

    const extractData = (RawFishdata) => {
      let modules = {};
      RawFishdata.forEach((el) => {
        modules = {
          ...modules,
          [el]: [],
        };
      });
      return modules;
    };
    var FishDataforGraph = {
      [this.state.iraBasicInfo?.activity]: {
        ...extractData(filtered),
      },
    };

    console.log(FishDataforGraph);

    this.setState({ FishData: FishDataforGraph });
  }

  iraDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        ira_id: this.state.iraId,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.iraModule.iraDetails,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          try {
            if (response.data.status == 200) {
              if (this.state.isDetailsLoadingFirstTime) {
                this.setState({ isDetailsLoadingFirstTime: false }, () => {
                  this.state.reportStatus == "completed" &&
                    (function () {
                      const chronologicalEvents =
                        response?.data?.data?.step_4?.chronology_of_events ||
                        [];

                      Highcharts.chart("ira-details-chronology-events", {
                        chart: {
                          type: "timeline",
                        },
                        accessibility: {
                          screenReaderSection: {
                            beforeChartFormat:
                              "<h5>{chartTitle}</h5>" +
                              "<div>{typeDescription}</div>" +
                              "<div>{chartSubtitle}</div>" +
                              "<div>{chartLongdesc}</div>" +
                              "<div>{viewTableButton}</div>",
                          },
                          point: {
                            valueDescriptionFormat:
                              "{index}. {point.label}. {point.description}.",
                          },
                        },
                        xAxis: {
                          visible: false,
                        },
                        yAxis: {
                          visible: false,
                        },
                        title: {
                          text: "",
                        },
                        subtitle: {
                          text: "",
                        },
                        colors: [
                          "#4185F3",
                          "#427CDD",
                          "#406AB2",
                          "#3E5A8E",
                          "#3B4A68",
                          "#363C46",
                        ],

                        series: [
                          {
                            data: chronologicalEvents
                              .map((ce, i) => ({
                                ...ce,
                                datetime: toDateByFormat(
                                  `${ce.date} ${ce.time}`,
                                  "dd/mm/yyyy hh:ii"
                                ),
                              }))
                              .sort(
                                (a, b) =>
                                  a.datetime.getTime() - b.datetime.getTime()
                              )
                              .map((o) => ({
                                name: o.info_source,
                                label: `${o.info_source}- ${o.date}:${o.time}`,
                                description: o.description,
                              })),
                          },
                        ],
                      });
                    })();
                });
              }

              if (response.data.data?.basic_ira.reported_by_id) {
                this.fetchReportingPerson(
                  response.data.data?.basic_ira.reported_by_id
                );
              }
              this._restrictExistingPersonFromDropDown(response.data.data);
              await this.setState(
                (prevState) => {
                  let {
                    dataLoading,
                    iraDetail,
                    iraCache,
                    iraBasicInfo,
                    iraFormSetting,
                    iraDetailStep1,
                    iraDetailStep2,
                    iraDetailStep3,
                    iraDetailStep4,
                    iraDetailStep5,
                    incidentPersonName,
                  } = prevState;
                  let { data, form_setting } = response.data;
                  dataLoading = false;
                  iraDetail = data;
                  iraBasicInfo = data.basic_ira;

                  //optimized code==========================
                  // iraDetailStep1 = data.step_1;

                  iraDetailStep1 = data.step_1List;

                  iraDetailStep2 = data.step_2;
                  iraDetailStep3 = data.step_3;
                  iraDetailStep4 = data.step_4;
                  iraDetailStep5 = data.step_5;
                  iraFormSetting = form_setting?.setting;
                  incidentPersonName = iraBasicInfo.incident_person_details;
                  return {
                    dataLoading,
                    iraCache: iraDetailStep5,
                    iraDetail,
                    iraBasicInfo,
                    iraFormSetting,
                    iraDetailStep1,
                    iraDetailStep2,
                    iraDetailStep3,
                    iraDetailStep4,
                    iraDetailStep5,
                    incidentPersonName,
                  };
                },
                () => {
                  this.fetchParameters();
                  this._InProgressContributingList();
                  this._InProgressImmediateList();
                  this._InProgressRcaList();
                  this.setFishData();
                  const involvedPersonsList =
                    this.state?.iraBasicInfo?.incident_person_details?.map(
                      (el) => parseInt(el.searched_incident_person_id)
                    );
                  if (involvedPersonsList.length) {
                    this.fetchUsersList(involvedPersonsList);
                  }
                }
              );
              this._fetchProjectListFlag();
            } else {
              this._toggleDataLoading(false);
            }
          } catch (error) {
            this._toggleDataLoading(false);
          }
        })
        .catch((error) => {
          this._toggleDataLoading(false);
        })
        .finally(() => {
          if (this.state.isDetailsLoadingFirstTime) {
            this.setState({ isDetailsLoadingFirstTime: false });
          }
        });
    });
  };

  //dayscount
  Dasycount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.iraModule.iraDaysCount,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: this.state.iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            completioncount: response.data.data.capa_completion_days,
            capacount: response.data.data.report_completion_days,
            opencount: response.data.data.recommendation_open_count,
            closecount: response.data.data.recommendation_close_count,
            gpcount: response.data.data.gp_count?.length
              ? response.data.data.gp_count
              : [{ gp_count: 0 }],
            tbtcount: response.data.data.tbt_count?.length
              ? response.data.data.tbt_count
              : [{ tbt_count: 0 }],
            trainingInternalCount: response.data.data.training_internal_count
              ?.length
              ? response.data.data.training_internal_count
              : [{ training_internal_count: 0 }],
            trainingExternalCount: response.data.data.training_external_count
              ?.length
              ? response.data.data.training_external_count
              : [{ training_external_count: 0 }],
            trainingEmployeeCount: response.data.data.training_employee_count
              ?.length
              ? response.data.data.training_employee_count
              : [{ training_employee_count: 0 }],
            trainingContractorCount: response.data.data
              .training_contractor_count?.length
              ? response.data.data.training_contractor_count
              : [{ training_contractor_count: 0 }],
            // gpcount: response.data.data.gp_count,
            // tbtcount: response.data.data.tbt_count,
            // trainingInternalCount: response.data.data.training_internal_count,
            // trainingExternalCount: response.data.data.training_external_count,
            // trainingEmployeeCount: response.data.data.training_employee_count,
            // trainingContractorCount:
            //   response.data.data.training_contractor_count,

            // likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
    this.setState({ confirmPopup: false });
    this.setState({ afterTargetDatePopup: false });
  };

  _handleBackButtonAction = () => {
    if (this.state.originFlag == "kanban") {
      return "/ira/kanban";
    } else {
      return "/";
    }
  };

  _handleLightBox = async (list) => {
    await this.setState({
      evidencelightbox: [],
      evidencelightboxType: [],
      lightboxactive: true,
      fsToggler1: !this.state.fsToggler1,
      evidencelightbox: list.map(
        (item) =>
          item.evidence || item.image_link || item.proof || item.witness_pic
      ),
      evidencelightboxType: list.map((item) => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      const format = data?.evidence_format ?? data.format;
      if (format !== undefined) {
        content.push({
          url: data.link ?? data.evidence_image,
          contentType:
            format != "text"
              ? format == "image" || format == "img"
                ? "image"
                : "video"
              : "video",
        });
      }
    });
    return content;
  };

  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getDepartment = (text = "") => {
    const [label = "", value] = text.split(":") ?? [];
    if ((label.trim() === "Department" && value.trim() !== "null") || null) {
      return value;
    }
    return "NA";
  };
  renderTimeLine = (reportData, state) => {
    if (state.dataLoading) {
      return [];
    }
    let details = [
      {
        title: <p className="clide-v2-fs-3">Reported Details</p>,
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                Reporting Date{" "}
              </div>
              <div className="clide-v2-title mb-1">
                <label className="">
                  {`${reportData.reported_date} `} | {reportData.reported_time}
                </label>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: <p className="clide-v2-fs-3">Incident Date</p>,
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                Date & Time{" "}
              </div>
              <div className="clide-v2-title mb-1">
                <label className="">
                  {`${reportData.incident_date} `} | {reportData?.incident_time}{" "}
                </label>
              </div>
            </div>
          </div>
        ),
      },
    ];
    if (reportData.incident_person_details?.length) {
      details = [
        ...details,
        ...reportData.incident_person_details?.map((image, index) => {
          return {
            title: <p className="clide-v2-fs-3">Incident Employee Details </p>,
            cardTitle: (
              <div className="d-flex p-1 text-start">
                <img
                  className="clide-v2-profile-img"
                  src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                  alt=""
                  title={image.incident_person_name}
                />

                <div className="p-1 px-3  w-100 mob-pe">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    Incident Person
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {image.incident_person_name} |{"NA"}
                      {image.incident_person_id ?? index + 1}
                    </label>
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }

    if (reportData.people_involved_list?.length) {
      details = [
        ...details,
        ...reportData.people_involved_list?.map((image, index) => {
          return {
            title: <p className="clide-v2-fs-3">Invited Team </p>,
            cardTitle: (
              <div className="d-flex p-1 text-start">
                <img
                  className="clide-v2-profile-img"
                  src={image.user_pic}
                  alt=""
                  title={image.user_name}
                />

                <div className="p-1 px-3  w-100 mob-pe">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    Team {image.status == 1 ? "Lead" : "Member"}
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label>{image.user_name}</label>
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }

    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <p className="clide-v2-fs-3">Latest Comment</p>,
          cardTitle: (
            <div
              className="d-flex p-1 text-start"
              onClick={() => {
                this.myRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img
                src={user_pic}
                className="clide-v2-profile-img"
                title={user_name}
                alt="avatar"
              />
              <div className="p-1 px-3  w-100 mob-pe">
                <div className="col-12 col-md-12 col-lg-12 px-0">Message</div>
                <div className="clide-v2-title mb-1" title={comment}> {comment.substring(0, 20) + "..."} </div>
                <span className="font-12 common-grey-1">{comment_time}</span>
              </div>
            </div>
          ),
        },
      ];
    }
    return details;
  };

  fetchActionMaster = () => {
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const details = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + "/listira/actionsmaster", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            actionsmaster: responseJson.data.list?.map((el) => {
              return {
                ...el,
                label: el.action,
                value: el?.id,
              };
            }),
          });
        }
      });
  };

  renderChronology = (arr, state, isActionEnabled = false) => {
    return arr?.map((reportData) => {
      return {
        title: <p className="clide-v2-fs-3">Info source</p>,
        cardTitle: (
          <div className="row">
            <div className="col-12 col-lg-10 col-md-10">
              <div className="row">
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="p-1 px-3  w-100 mob-pe">
                    <div className="col-12 col-md-12 col-lg-12 px-0">Date </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">{reportData?.date}</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="p-1 px-3  w-100 mob-pe">
                    <div className="col-12 col-md-12 col-lg-12 px-0">Time </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">{reportData?.time}</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="p-1 px-3  w-100 mob-pe">
                    <div className="col-12 col-md-12 col-lg-12 px-0">
                      Info Source{" "}
                    </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">{reportData?.info_source}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  d-flex gap-2">
              {isActionEnabled ? (
                <>
                  <div>
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.manageChoronologyPayload(reportData);
                        })
                      }
                      className="clide-v2-icon-btn "
                    >
                      <RoundEdit />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.manageDataGatheringParams(
                            "",
                            {
                              isDeleteEnable: true,
                              parameters: {
                                event_detail_id: reportData?.id,
                              },
                              url: "/listira/deletechronologyofevents",
                            },
                            false,
                            "deleteParams"
                          );
                        })
                      }
                      className="clide-v2-icon-btn "
                    >
                      <RoundDelete />
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ),
      };
    });
  };

  resetIntialParams = () => {
    this.setState({
      addIntialResponse: false,
      initialResponse: {
        ...this.state.initialResponse,
        isLoading: false,
        description: "",
        actions: [],
        editId: "",
      },
    });
  };

  _initialresponseAdd = async () => {
    this.setState({
      initialResponse: {
        ...this.state.initialResponse,
        isLoading: true,
      },
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const { description, actions, editId } = this.state.initialResponse;
    const list = actions.map((el) => el.value);
    let data = {
      auth_key,
      user_id,
      ira_id: this.state.iraId,
      service_id: this.state.iraBasicInfo.service_id,
      project_id: this.state.iraBasicInfo.project_id,
      [editId !== "" ? "edited_response" : "description"]: description,
      actions: list,
      initial_response_id: editId !== "" ? editId : undefined,
    };
    const basePath =
      editId !== "" ? apiUrl.step1.edit : "/listira/addinitialresponse";
    fetch(Url.clientURL + basePath, {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.resetIntialParams();
      });
  };

  manageIntialParams = (editId) => {};
  manageNestedState = (moduleName = "", val = {}) => {
    this.setState({
      [moduleName]: {
        ...this.state[moduleName],
        ...val,
      },
    });
  };
  _checkSettingParameter = (checkingParam = "") => {
    let checkingText = checkingParam.toLowerCase();
    const settingList = this.state.iraFormSetting[0]?.step_setting || [];
    return settingList.some(
      (item) =>
        item.parameter.toLowerCase() == checkingText && item.parameter_value
    );
  };

  manageDataGatheringParams = (
    moduleName,
    val,
    reset = false,
    objName = "dataGathering",
    intial = intialValue.dataGathering
  ) => {
    if (reset) {
      this.setState({
        [objName]: {
          ...intial,
        },
        questionArray: [],
      });
      return;
    }
    this.setState((prev) => {
      return {
        [objName]: {
          ...prev[objName],
          ...(objName !== "dataGathering"
            ? { ...val }
            : {
                [moduleName]: {
                  ...prev[objName][moduleName],
                  ...val,
                },
              }),
        },
      };
    });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _pickCropImage = async (e, module = "witness") => {
    const lowStorage = this.checkLowStorage();
    let fileObj = [];
    let fileArray = [];
    let files = [...(this.state.dataGathering[module]["files"] ?? [])];
    fileObj.push(e.target.files);

    lowStorage
      .then(async (response) => {
        const { status, msg } = response;

        if (status == 200) {
          for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(fileObj[0][i]);
            let obj = {
              file: fileObj[0][i],
              file_status: 1,
              file_id: "",
            };
            files.push(obj);
          }
          this.manageDataGatheringParams(module, {
            files: fileArray,
            files,
          });
        } else {
          await this.setState({
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addpeoples = async () => {
    this.manageDataGatheringParams("witness", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const {
      witness_name,
      contractor_company,
      employee_contractor_status,
      files,
      witness_statement,
      isEdit,
      witness_evidence_id = "",
    } = this.state.dataGathering.witness ?? {};
    const isEditEnabled = isEdit !== "";
    let data = new FormData();
    data.append("auth_key", auth_key);
    data.append("user_id", user_id);
    data.append("service_id", this.state.iraBasicInfo.service_id);
    data.append("project_id", this.state.iraBasicInfo.project_id);
    data.append("ira_id", this.state.iraId);
    data.append("employee_contractor_status", employee_contractor_status);
    data.append("contractor_company", contractor_company);
    data.append("witness_name", witness_name);
    data.append("witness_statement", witness_statement);
    // data.append('record', '');

    // console.warn(data);
    if (isEditEnabled) {
      data.append("witness_id", isEdit);
      data.append("witness_evidence_id", witness_evidence_id);
    }
    if (files != "") {
      data.append("file_status", 1);
      files.forEach((el) => {
        if (typeof el === "object") {
          data.append("file", el.file);
        }
      });
    } else {
      data.append("file_status", 0);
    }
    const basePath = isEditEnabled
      ? "/listira/step3editpeoples"
      : "/listira/step3addpeoples";
    axios
      .post(Url.clientURL + basePath, data)
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  getCarouselProps = (arr = [], callback) => {
    return arr.map((el) => {
      return callback(el);
    });
  };
  fetchInfoMaster = () => {
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    let details = {
      auth_key,
      user_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraotherinfomasterlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          let OtherInfomasterlist = responseJson.data.questions.map(function (
            o
          ) {
            o.response = null;
            o.notes = "";
            return o;
          });
          this.setState({
            OtherInfomasterlist,
            tempQuestionList: OtherInfomasterlist,
          });
        }
        // console.warn(this.state.OtherInfomasterlist)
      });
  };

  insertPosts = () => {
    this.manageDataGatheringParams("posts", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");
    const { file_description, files, module, savedPalyoad } =
      this.state.dataGathering.posts ?? {};
    const isEditEnabled = savedPalyoad.length;
    let databody = new FormData();
    databody.append("auth_key", auth_key);
    databody.append("user_id", user_id);
    databody.append("service_id", this.state.iraBasicInfo.service_id);
    databody.append("project_id", this.state.iraBasicInfo.project_id);
    databody.append("ira_id", this.state.iraId);
    databody.append("file_description", file_description);
    if (isEditEnabled) {
      databody.append("image_id", savedPalyoad[0]["image_id"]);
    }
    if (files.length) {
      files.forEach((el) => {
        databody.append("file", el.file);
      });
    }

    axios
      .post(
        Url.clientURL + postUrl[module][isEditEnabled ? "edit" : "add"],
        databody
      )
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  insertAnalysisQuestions = () => {};
  fetchParameters = () => {
    this.manageDataGatheringParams(
      "",
      {
        isLoading: true,
      },
      false,
      "categoryList"
    );
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.parameterList, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.data) {
            this.manageDataGatheringParams(
              "",
              {
                data: {
                  category: responseJson.data.category_list || [],
                  responsible_person:
                    responseJson.data.responsible_person || [],
                },
              },
              false,
              "categoryList"
            );
          }
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      })
      .finally(() => {
        this.manageDataGatheringParams(
          "",
          {
            isLoading: false,
          },
          false,
          "categoryList"
        );
      });
  };

  generateDropdownParameter = (arr = [], key = {}) => {
    return arr?.map((el) => {
      return {
        id: el[key?.id]?.toString(),
        label: el[key?.label],
      };
    });
  };

  onPriorityChange(num) {
    if (num == 0) {
      this.setState({
        priority: { extreme: true, high: false, medium: false, low: false },
      });
      this.setState({ priority_l: 4 });
    }
    if (num == 1) {
      this.setState({
        priority: { extreme: false, high: true, medium: false, low: false },
      });
      this.setState({ priority_l: 3 });
    }
    if (num == 2) {
      this.setState({
        priority: { extreme: false, high: false, medium: true, low: false },
      });
      this.setState({ priority_l: 2 });
    }
    if (num == 3) {
      this.setState({
        priority: { extreme: false, high: false, medium: false, low: true },
      });
      this.setState({ priority_l: 1 });
    }
  }

  manageCapaValidation = (isEditTrue = false) => {
    const { responsible_p, correctiveActionText, category_l, selectedDate } =
      this.state.dataGathering.recommendation;
    const { capaType, priority_l } = this.state;
    let isErrorEnabled = false;
    if (
      !responsible_p?.length ||
      !category_l?.length ||
      !capaType ||
      !correctiveActionText ||
      !selectedDate ||
      (!isEditTrue && !priority_l)
    ) {
      isErrorEnabled = true;
    }
    this.setState({
      iAssignErrorEnabled: !responsible_p?.length,
      isCategoryErrorEnabled: !category_l?.length,
      isCorretiveActionErrorEnabled: !correctiveActionText,
      isCAPAErrorEnabled: !capaType,
      isDueDateErrorEnabled: !selectedDate,
      isPriortiyErrorEnabled: isEditTrue ? false : !priority_l,
    });
    return isErrorEnabled;
  };

  _AddRecommendation = async () => {
    if (this.manageCapaValidation()) {
      return false;
    }
    const { category_l, responsible_p, selectedDate, correctiveActionText } =
      this.state.dataGathering.recommendation;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const ira_id = this.state.iraId;

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const category_id = category_l[0].id;
    const recommendation = correctiveActionText;
    const target_date = moment(selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const responsible_person_id = responsible_p[0]?.id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      ira_id,
      category_id,
      recommendation,
      target_date,
      priority,
      capa_type: this.state.capaType,
      responsible_person_id,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.add, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
        console.log("sayan error");
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          priority: { extreme: false, high: false, medium: false, low: false },
          capaType: "",
        });
      });
  };

  manageEdit = (id = "", item = {}, arr = []) => {
    console.log(id, arr);
    this.manageDataGatheringParams("recommendation", {
      editId: id,
      isAddEnable: true,
      category_l: this.generateDropdownParameter(
        this.state.categoryList.data.category.filter(
          (el) => el.category_id === item.category_id
        ),
        {
          id: "category_id",
          label: "category",
        }
      ),
      responsible_p: this.generateDropdownParameter(
        this.state.categoryList.data.responsible_person.filter(
          (el) => el.person_id === item.responsible_person[0]["person_id"]
        ),
        {
          id: "person_id",
          label: "person",
        }
      ),
      selectedDate: _momentDate(item?.target_date),
      correctiveActionText:
        item?.recommendation?.map((el) => el?.recommendation) ?? "",
    });
    this.setState({
      priority: {
        extreme: item?.priority === "Critical",
        high: item?.priority === "Urgent",
        medium: item?.priority === "Important",
        low: item?.priority === "Normal",
      },
      capaType: item.recommendation[0]?.capaType,
    });
  };

  approveRecommendation = (approveId) => {
    const itemsLength =
      this.state.iraDetailStep5?.praposed_corrective_action?.length;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: approveId,
    };
    // console.warn(data)

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.approve, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ message: responseJson.msg });
          if (itemsLength === 1) {
            this.setState({ currentTab: "final" }, this.iraDetails);
          }

          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  removeFinalRecord = (id) => {
    const itemsLength =
      this.state.iraDetailStep5?.final_corrective_action?.length;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: id,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step5.final.delete, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        if (itemsLength === 1) {
          this.setState({ currentTab: "prop" }, this.iraDetails);
        }
        this.iraDetails();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  finalRecordFreeze = (id) => {
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const selected_recommendations =
      this.state.iraDetailStep5?.final_corrective_action?.map(
        (el) => el.recommended_id
      );
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      selected_recommendations,
    };

    fetch(Url.clientURL + apiUrl.step5.final.freeze, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: qs.stringify(data, { arrayFormat: "repeat" }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          AlertMessage({
            type: "success",
            message: res?.msg ?? "Record  completed ",
          });
          this.props.history.push(`/ira-detail/${ira_id}/kanban/completed`);
          window.location.reload();
        } else {
          AlertMessage({
            type: "error",
            message: res?.msg ?? "Something went wrong",
          });
        }

        // this._handleBackButtonAction();
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          isFreezeEnabled: false,
        });
      });
  };

  fetchInjuredParts = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/irainjuredparts", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraInjuredParts: this.generateDropdownParameter(
              responseJson.data.injured_parts,
              {
                id: "id",
                label: "injured_part",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  fetchWorkingShift = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraworkingshift", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            workingShift: this.generateDropdownParameter(
              responseJson.data.working_shift,
              {
                id: "id",
                label: "working_shift",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIncidentQuestions = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraincidentanalysisquestions", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            questionMaster: this.generateDropdownParameter(
              responseJson.data.questions,
              {
                id: "question_id",
                label: "question",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIdentityProof = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraidentityproof", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraidentityProof: this.generateDropdownParameter(
              responseJson.data.identity_proof,
              {
                id: "id",
                label: "identity_proof",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchIraIncidentTime = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iratimeofincident", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraTimeOfIncident: this.generateDropdownParameter(
              responseJson.data.incident_time,
              {
                id: "id",
                label: "incident_time",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchWorkType = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iraworktype", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({
            iraWorkType: this.generateDropdownParameter(
              responseJson.data.work_type,
              {
                id: "id",
                label: "work_type",
              }
            ),
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  _restrictExistingPersonFromDropDown = async (data) => {
    const addedInjuredPersonList = _getAddedInjuredPersonName(
      data.step_2.injured_person_details
    );
    try {
      const tempFinalList = data.basic_ira.incident_person_details.filter(
        (el) => {
          if (!addedInjuredPersonList.includes(el.incident_person_name)) {
            return el;
          }
        }
      );

      await this.setState({
        injuredPersonList: this.generateDropdownParameter(tempFinalList, {
          id: "incident_person_name",
          label: "incident_person_name",
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };
  removePropRecord = (id) => {
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      recommendation_id: id,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step5.propsed.delete, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        this.iraDetails();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  _EditRecommendation = () => {
    if (this.manageCapaValidation(true)) {
      return false;
    }
    const { capaType, dataGathering } = this.state ?? {};
    const {
      category_l,
      responsible_p,
      selectedDate,
      correctiveActionText,
      editId,
    } = dataGathering?.recommendation;
    this.manageDataGatheringParams("recommendation", {
      isLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const ira_id = this.state.iraId;

    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const category_id = category_l[0].id;
    const recommendation = correctiveActionText;
    const target_date = moment(selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const responsible_person_id = responsible_p[0].id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      ira_id,
      category_id,
      recommendation,
      target_date,
      priority,
      recommendation_id: editId,
      responsible_person_id,
      capa_type: capaType,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Url.clientURL + apiUrl.step5.propsed.edit, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
        this.setState({
          priority: { extreme: false, high: false, medium: false, low: false },
          capaType: "",
        });
      });
  };
  makeFilterList = () => {
    this.manageDataGatheringParams("filterParams", {
      isLoading: true,
    });
    const user_id = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const isPropsed = this.state.currentTab === "prop" ? 0 : 1;
    const { extremeSelect, highSelect, mediumSelect, lowSelect } = this.state;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      ira_id,
      category: this.state.dataGathering.filterParams.category[0]?.id ?? "",
      recommendation_by:
        this.state.dataGathering.filterParams.recommendation_by[0]?.id ?? "",
      responsible_person_id:
        this.state.dataGathering.filterParams.responsible_person_id[0]?.id ??
        "",
      // startDate: this.state.dataGathering.filterParams.startDate,
      // endDate: this.state.dataGathering.filterParams.endDate,
      startDate:
        this.state.dataGathering.filterParams.startDate !== null
          ? moment(this.state.dataGathering.filterParams.startDate).format(
              "YYYY-MM-DD"
            )
          : "",
      endDate:
        this.state.dataGathering.filterParams.endDate !== null
          ? moment(this.state.dataGathering.filterParams.endDate).format(
              "YYYY-MM-DD"
            )
          : "",
      list_type: isPropsed,
      page_no: 0,
      priority: [
        extremeSelect ? extremeSelect : undefined,
        highSelect ? highSelect : undefined,
        mediumSelect ? mediumSelect : undefined,
        lowSelect ? lowSelect : undefined,
      ],
    };
    axios
      .post(
        Url.clientURL + apiUrl.step5.filter,
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            iraDetailStep5: {
              ...this.state.iraDetailStep5,
              [isPropsed === 0
                ? "praposed_corrective_action"
                : "final_corrective_action"]: res.data.data.recommendationlist,
            },
          });
        } else if (res.data.status === 500) {
          AlertMessage({
            type: "error",
            message: "Something is wrong ",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.manageDataGatheringParams("filterParams", {
          isLoading: false,
          isFilterEnable: false,
        });
      });
  };
  generateTheIdProof = (e, category) => {
    const lowStorage = this.checkLowStorage();
    const { files } = e.target;
    const { name } = files[0];
    lowStorage
      .then(async (response) => {
        const { status, msg } = response;

        if (status == 200) {
          toDataURL(files[0], async (url) => {
            const content = this.state.identity_proof_base64.concat(url);
            await this.setState({ identity_proof_base64: content });
            const { editId, identity_proof } =
              this.state.dataGathering.investigation;
            let data = {
              proof_data: "file://" + url,
              proof: this.state.identity_proof_base64,
              name: name,
              category,
            };
            if (editId !== "") {
              data = {
                ...data,
                proof_id: identity_proof[0]?.proof_id,
              };
            }
            this.setState({
              dataGathering: {
                ...this.state.dataGathering,
                investigation: {
                  ...this.state.dataGathering.investigation,
                  identity_proof: [
                    ...this.state.dataGathering.investigation.identity_proof,
                    data,
                  ],
                },
              },
            });
          });
        } else {
          await this.setState({
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterQuestionDropDown = (state) => {};
  manageQuestion = (res = "", arr = [], idx) => {
    const content = Array.from(arr);
    content[idx]["response"] = res;
    this.setState({
      questionArray: content,
    });
  };
  saveIntialInvestigation = () => {
    const hasErrors = this.validateStep2Fields();
    if (hasErrors.length) {
      return false;
    }
    this.manageDataGatheringParams("investigation", {
      isLoading: true,
    });
    const userId = localStorage.getItem("userId");
    let auth_key = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;

    const {
      employee_contractor_status,
      injuredPersonType,
      injured_person_name,
      contractor_company_name,
      identity_proof,
      joining_date,
      month_with_this_emp,
      year_with_this_emp,
      month_doing_this_job,
      year_doing_this_job,
      time_of_incident,
      working_shift,
      injured_parts,
      editId,
      person_work_type,
      injured_person_age,
      proofType,
      injured_person_gender,
      injured_type_id,
    } = this.state.dataGathering.investigation ?? {};
    const isEditEnabled = editId !== "";
    const injured_person_details = {
      employee_contractor_status,
      injured_person_name: isEditEnabled
        ? injured_person_name
        : injuredPersonType === "other"
        ? injured_person_name
        : injuredPersonType,
      injured_person_age,
      injured_person_gender: injured_person_gender?.length
        ? injured_person_gender[0]?.label
        : "",
      injured_type_id: injured_type_id?.length ? injured_type_id[0]?.id : "",
      contractor_company_name,
      identity_proof: identity_proof.filter((el) => {
        if (Object.hasOwn(el, "name")) {
          return {
            ...el,
          };
        }
      })[0],
      person_work_type: person_work_type?.length ? person_work_type[0].id : "",
      joining_date: moment(joining_date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      month_with_this_emp: month_with_this_emp?.length
        ? month_with_this_emp[0]?.id
        : "",
      year_with_this_emp: year_with_this_emp?.length
        ? year_with_this_emp[0]?.id
        : "",
      month_doing_this_job: month_doing_this_job?.length
        ? month_doing_this_job[0]?.id
        : "",
      year_doing_this_job: year_doing_this_job?.length
        ? year_doing_this_job[0]?.id
        : "",
      time_of_incident: time_of_incident?.length ? time_of_incident[0]?.id : "",
      working_shift: working_shift?.length ? working_shift[0]?.id : "",
      injured_parts: injured_parts?.map((el) => {
        return { injured_parts_id: el.id };
      }),
    };
    const data = {
      auth_key,
      userId,
      service_id,
      project_id,
      ira_id,
      injured_person_details,
      injured_person_id: editId,
      incident_analysis_questions: this.state.questionArray.map((el) => {
        return {
          question_id: el.question_id,
          response: el.response,
        };
      }),
    };
    if (!isEditEnabled) {
      delete data.injured_person_id;
    }

    fetch(Url.clientURL + apiUrl.step2[isEditEnabled ? "edit" : "add"], {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            type: "success",
            message: `Record ${
              isEditEnabled ? "updated" : "created"
            } Successfully`,
          });
          this.iraDetails();
        } else if (responseJson.status === 500 || 401) {
          AlertMessage({
            type: "error",
            message: "An error occurred",
          });
        }
        this.iraDetails();
      })
      .catch(() => {
        AlertMessage({
          type: "error",
          message: "An error occurred",
        });
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  insertChronology = () => {
    this.manageDataGatheringParams("chronology", {
      isLoading: true,
    });
    const userKey = localStorage.getItem("userId");
    let auth_key = localStorage.getItem("token");
    const ira_id = this.state.iraId;
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    const { date, description, info_source, dateTimeOf, isEdit } =
      this.state.dataGathering.chronology ?? {};
    const isEditEnabled = isEdit !== "";
    const keys = {
      date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      time: moment(dateTimeOf).format("HH:mm"),
      description,
      info_source,
    };
    const data = {
      auth_key,
      [isEditEnabled ? "userId" : "user_id"]: userKey,
      service_id,
      project_id,
      ira_id,
      chronology_of_events: [keys],
      ...(!isEditEnabled && {
        ...keys,
      }),
      event_id: isEditEnabled ? isEdit : undefined,
    };
    if (!isEditEnabled) {
      delete data.event_id;
      delete data.chronology_of_events;
    }

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(
      Url.clientURL + apiUrl.step4.chronology[isEditEnabled ? "edit" : "add"],
      {
        method: "post",
        headers: {
          "Content-Type": isEditEnabled
            ? "application/json"
            : "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: isEditEnabled ? JSON.stringify(data) : formBody,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          window.location.reload();
          this.iraDetails();
        }
      })
      .catch((error) => {
        //throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  fetchRootCauseParameters = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step4.rootCause.parameterList, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              masterList: responseJson.data,
              main_category: responseJson.data.main_category,
              root_cause_type: responseJson.data.root_cause_type,
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  generateCommonParams = () => {
    return {
      auth_key: localStorage.getItem("token"),
      user_id: localStorage.getItem("userId"),
      ira_id: this.state.iraId,
      project_id: this.state.iraBasicInfo.project_id,
      service_id: this.state.iraBasicInfo.service_id,
    };
  };
  addProbableCause = () => {
    this.manageDataGatheringParams("rootCause", {
      isLoading: true,
    });
    const { description, probable_cause, editId } =
      this.state.dataGathering.rootCause ?? {};
    const isEditEnabled = editId !== "";
    let data = {
      ...this.generateCommonParams(),
      description,
      probable_cause,
      probable_cause_id: editId,
    };
    if (!isEditEnabled) {
      delete data.probable_cause_id;
    }
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + apiUrl.step4.rootCause.addPobableCause, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  generateValue = (arr = [], idx, val = {}) => {
    const elements = [...arr];
    elements[idx] = val[0];
    return elements;
  };

  // __parseChild = (arr = [], moduleName = "", parentId, idKey) => {
  //   const element = [];
  //   forEach((el) => {

  //   })
  // }
  getMajorCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            data.push(sub);
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["major_root_cause_category"],
        nestedListParser.major_root_cause_category
      );
    }
  };
  getNearCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            sub.major_root_cause_category.filter((third) => {
              if (
                third.major_root_cause_category_id.toString() ===
                rootCause?.main_category[2]["id"]
              ) {
                data.push(third);
              }
            });
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["near_root_cause_category"],
        nestedListParser.near_root_cause_category
      );
    }
  };
  getImmediateCauseList = (masterArr = [], rootCause = []) => {
    const data = [];
    masterArr.filter((el) => {
      if (
        el.main_category_id.toString() === rootCause?.main_category[0]["id"]
      ) {
        el.sub_category.filter((sub) => {
          if (
            sub.sub_category_id.toString() === rootCause?.main_category[1]["id"]
          ) {
            sub.major_root_cause_category.filter((third) => {
              if (
                third.major_root_cause_category_id.toString() ===
                rootCause?.main_category[2]["id"]
              ) {
                third.near_root_cause_category.filter((fourth) => {
                  if (
                    fourth.near_root_cause_category_id.toString() ===
                    rootCause?.main_category[3]["id"]
                  ) {
                    data.push(fourth);
                  }
                });
              }
            });
          }
        });
      }
    });
    if (data.length) {
      return this.generateDropdownParameter(
        data[0]["intermediate_cause_category"],
        nestedListParser.intermediate_cause_category
      );
    }
  };
  addRootCause = () => {
    this.manageDataGatheringParams("rootCause", {
      isLoading: true,
    });
    const { contributing_factor, editId } =
      this.state.dataGathering.rootCause ?? {};
    const isEditEnabled = editId !== "";
    let tempCat = [...this.state.main_category];

    const mainCat = tempCat
      .filter((item) => item.isChecked)
      .map((item) => {
        return {
          main_category_id: item.main_category_id,
          sub_category: item.sub_category
            .filter((subItem) => subItem.isChecked)
            .map((subItem) => {
              return {
                sub_category_id: subItem.sub_category_id,
                major_root_cause_category: subItem.major_root_cause_category
                  .filter((majorItem) => majorItem.isChecked)
                  .map((majorItem) => {
                    return {
                      major_root_cause_category_id:
                        majorItem.major_root_cause_category_id,
                      near_root_cause_category:
                        majorItem.near_root_cause_category
                          .filter((nearItem) => nearItem.isChecked)
                          .map((nearItem) => {
                            return {
                              near_root_cause_category_id:
                                nearItem.near_root_cause_category_id,
                              intermediate_cause_category:
                                nearItem.intermediate_cause_category
                                  .filter((interItem) => interItem.isChecked)
                                  .map((interItem) => {
                                    return {
                                      intermediate_cause_category_id:
                                        interItem.intermediate_cause_category_id,
                                    };
                                  }),
                            };
                          }),
                    };
                  }),
              };
            }),
        };
      });

    // main route cause
    let tempRoot = [...this.state.root_cause_type];

    const mainRoot = tempRoot
      .filter((item) => item.isChecked)
      .map((item) => {
        return {
          root_cause_type_id: item.root_cause_type_id,
          root_cause: item.root_cause
            .filter((subItem) => subItem.isChecked)
            .map((subItem) => {
              return {
                root_cause_id: subItem.root_cause_id,
              };
            }),
        };
      });
    let data = {
      ...this.generateCommonParams(),
      userId: localStorage.getItem("userId"),
      main_category: mainCat,
      root_cause_type: mainRoot,
      contributing_factor,
    };
    // if (!isEditEnabled) {
    //   // delete data.user_id
    // } else {
    //   delete data.userId
    // }

    fetch(
      Url.clientURL +
        apiUrl.step4.rootCause[
          isEditEnabled ? "editRouteCause" : "addRouteCause"
        ],
      {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };
  getChildren = (obj) => {
    const data = [];
    const element = Object.entries(obj).map((el) => {
      if (typeof el[1] === "object") {
        data.push(el[1]);
      }
    });
    return data[0];
  };
  renderNestedPart = (data = []) => {
    if (!data.length) {
      return;
    }
    const parameters = Object.keys(nestedListParser);
    return Object.entries(data)?.map((el) => {
      return Object.entries(el[1]).map((sub) => {
        const [key, value] = sub;
        const children = this.getChildren(el[1]) ?? {};
        if (parameters.includes(key) && typeof value !== "object") {
          return (
            <TreeNode
              label={
                <StyledNode>
                  {" "}
                  <div className="d-flex align-items-center">
                    <Book className="mr-3 text-danger" />{" "}
                    <span className="slds-form-element__label  font-weight-500">
                      {" "}
                      {value}{" "}
                    </span>
                  </div>
                </StyledNode>
              }
            >
              {children?.length ? this.renderNestedPart(children) : null}
            </TreeNode>
          );
        }
      });
    });
  };
  renderTree = (menu, rootName = "root", isRoot = false) => {
    if (Object.keys(menu).length > 2 && !this.state.dataLoading) {
      const { probable_cause, contributing_factor } = menu ?? {};
      const { main_category, root_cause_type } = contributing_factor ?? {};
      return (
        <Tree
          lineHeight={"30px"}
          nodePadding={"20px"}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
          label={
            <StyledNode>
              {rootName !== "" ? "Root Cause" : probable_cause?.probable_cause}
            </StyledNode>
          }
        >
          {this.renderNestedPart(isRoot ? main_category : root_cause_type)}
        </Tree>
      );
    }
  };
  _toggleMainCategoryCheck = async (val) => {
    let factorIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === val
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;
      let tempMainCat = { ...main_category[factorIndex] };
      tempMainCat.isChecked = !tempMainCat.isChecked;
      main_category[factorIndex] = tempMainCat;
      return { main_category };
    });
  };
  _toggleSubCategoryCheck = async (mainCatId, subCatId) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category, majorRouteCauseArr } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      tempSubCat.isChecked = !tempSubCat.isChecked;

      tempMainCat.sub_category[subIndex] = tempSubCat;

      main_category[mainIndex] = tempMainCat;

      return { main_category };
    });
  };
  _toggleMajorCategoryCheck = async (mainCatId, subCatId, majorCatId) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;

      let tempMainCat = { ...main_category[mainIndex] };

      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };
      tempMajorCat.isChecked = !tempMajorCat.isChecked;

      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      // Activating Near route cause
      const tempNew = main_category[mainIndex].sub_category.filter(
        (item) => item.isChecked
      );
      let showNear = false;
      tempNew.forEach((subItem) => {
        const tempArr = subItem.major_root_cause_category.filter(
          (majorItem) => majorItem.isChecked
        );
        if (tempArr.length > 0) {
          showNear = true;
          return;
        }
      });

      let tempNewMainCat = { ...main_category[mainIndex] };
      tempNewMainCat.showNearCouse = showNear;
      main_category[mainIndex] = tempNewMainCat;

      return { main_category };
    });
  };
  _toggleNearCategoryCheck = async (
    mainCatId,
    subCatId,
    majorCatId,
    NearCatId
  ) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      // Accessing sub category
      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      // Accessing major cause
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };

      // Accessing near cause
      let nearIndex = tempMajorCat.near_root_cause_category.findIndex(
        (item) => item.near_root_cause_category_id === NearCatId
      );
      let tempNearCat = { ...tempMajorCat.near_root_cause_category[nearIndex] };

      tempNearCat.isChecked = !tempNearCat.isChecked;

      tempMajorCat.near_root_cause_category[nearIndex] = tempNearCat;
      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      //Activating Intermediate route cause ( need check if at least one checkbox is checked then show intermediate causes)
      const tempSub = main_category[mainIndex].sub_category.filter(
        (item) => item.isChecked
      );
      let showInter = false;

      tempSub.forEach((subItem) => {
        const tempMajor = subItem.major_root_cause_category.filter(
          (majorItem) => majorItem.isChecked
        );

        tempMajor.forEach((subMajor) => {
          const tempNear = subMajor.near_root_cause_category.filter(
            (nearItem) => nearItem.isChecked
          );
          if (tempNear.length > 0) {
            showInter = true;
            return;
          }
        });
      });

      // Setting hide/show intercause
      let tempNewMainCat = { ...main_category[mainIndex] };
      tempNewMainCat.showInterCouse = showInter;
      main_category[mainIndex] = tempNewMainCat;

      return { main_category };
    });
  };
  _toggleInterCategoryCheck = async (
    mainCatId,
    subCatId,
    majorCatId,
    nearCatId,
    interCatID
  ) => {
    let mainIndex = this.state.main_category.findIndex(
      (item) => item.main_category_id === mainCatId
    );

    await this.setState((prevState) => {
      let { main_category, intermediateRouteCause } = prevState;
      let tempMainCat = { ...main_category[mainIndex] };

      // Accessing sub category
      let subIndex = tempMainCat.sub_category.findIndex(
        (item) => item.sub_category_id === subCatId
      );
      let tempSubCat = { ...tempMainCat.sub_category[subIndex] };

      // Accessing major cause
      let majorIndex = tempSubCat.major_root_cause_category.findIndex(
        (item) => item.major_root_cause_category_id === majorCatId
      );
      let tempMajorCat = {
        ...tempSubCat.major_root_cause_category[majorIndex],
      };

      // Accessing near cause
      let nearIndex = tempMajorCat.near_root_cause_category.findIndex(
        (item) => item.near_root_cause_category_id === nearCatId
      );
      let tempNearCat = { ...tempMajorCat.near_root_cause_category[nearIndex] };

      // Accessing inter cause
      let interIndex = tempNearCat.intermediate_cause_category.findIndex(
        (item) => item.intermediate_cause_category_id === interCatID
      );
      let tempInterCat = {
        ...tempNearCat.intermediate_cause_category[interIndex],
      };

      tempInterCat.isChecked = !tempInterCat.isChecked;

      tempNearCat.intermediate_cause_category[interIndex] = tempInterCat;
      tempMajorCat.near_root_cause_category[nearIndex] = tempNearCat;
      tempSubCat.major_root_cause_category[majorIndex] = tempMajorCat;
      tempMainCat.sub_category[subIndex] = tempSubCat;
      main_category[mainIndex] = tempMainCat;

      let showRouteCause = false;

      //Activating Intermediate route cause ( need check if at least one checkbox is checked then show intermediate causes)
      main_category.forEach((mainItem) => {
        const tempSub = mainItem.sub_category.filter((item) => item.isChecked);

        tempSub.forEach((subItem) => {
          const tempMajor = subItem.major_root_cause_category.filter(
            (majorItem) => majorItem.isChecked
          );

          tempMajor.forEach((subMajor) => {
            const tempNear = subMajor.near_root_cause_category.filter(
              (nearItem) => nearItem.isChecked
            );

            tempNear.forEach((nearItem) => {
              const tempInter = nearItem.intermediate_cause_category.filter(
                (interItem) => interItem.isChecked
              );

              if (tempInter.length > 0) {
                showRouteCause = true;
                return;
              }
            });
          });
        });
      });

      intermediateRouteCause = showRouteCause;
      return { main_category, intermediateRouteCause };
    });
  };
  _toggleRootCauseTypeCheck = async (typeId) => {
    let typeIndex = this.state.root_cause_type.findIndex(
      (item) => item.root_cause_type_id === typeId
    );

    await this.setState((prevState) => {
      let { root_cause_type } = prevState;
      let typeCause = { ...root_cause_type[typeIndex] };
      typeCause.isChecked = !typeCause.isChecked;
      root_cause_type[typeIndex] = typeCause;
      return { root_cause_type };
    });
  };
  _toggleRootCauseCheck = async (typeId, causeId) => {
    let typeIndex = this.state.root_cause_type.findIndex(
      (item) => item.root_cause_type_id === typeId
    );

    await this.setState((prevState) => {
      let { root_cause_type } = prevState;
      let typeCause = { ...root_cause_type[typeIndex] };
      let causeIndex = typeCause.root_cause.findIndex(
        (item) => item.root_cause_id === causeId
      );
      let tempCause = { ...typeCause.root_cause[causeIndex] };
      tempCause.isChecked = !tempCause.isChecked;
      typeCause.root_cause[causeIndex] = tempCause;
      root_cause_type[typeIndex] = typeCause;
      return { root_cause_type };
    });
  };
  fetchReturningList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/irareturntowork", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              returnToWorkList: this.generateDropdownParameter(
                responseJson.data.return_to_work,
                {
                  id: "id",
                  label: "return_to_work_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchGenderList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iradetailsgenderlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              genderList: this.generateDropdownParameter(
                responseJson.data.iraGenderList,
                {
                  id: "id",
                  label: "ira_gender_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchInjuryTypeList = () => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("userId");
    let data = {
      auth_key: token,
      user_id,
      page_no: 1,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/listira/iradetailsinjurytypelist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.setState({
              injurtType: this.generateDropdownParameter(
                responseJson.data.injuryTypeList,
                {
                  id: "id",
                  label: "injury_type_info",
                }
              ),
            });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsBinaryString(file);
    });
  };
  insertCost = async () => {
    this.manageDataGatheringParams("cost", {
      isLoading: true,
    });
    const { mandays_lost, cost_involved, return_to_work_id, files, editId } =
      this.state.dataGathering.cost ?? {};
    const isEditEnabled = editId !== undefined;

    const user_id = localStorage.getItem("userId");
    const auth_key = localStorage.getItem("token");

    let uploadData = new FormData();
    // const file = await this.imageToBinary(files[0].file);

    uploadData.append("auth_key", auth_key);
    uploadData.append("user_id", user_id);
    uploadData.append("ira_id", this.state.iraId);
    uploadData.append("project_id", this.state.iraBasicInfo.project_id);
    uploadData.append("service_id", this.state.iraBasicInfo.service_id);
    uploadData.append(
      isEditEnabled ? "edited_mandays_lost" : "mandays_lost",
      mandays_lost
    );
    uploadData.append(
      isEditEnabled ? "edited_cost_involved" : "cost_involved",
      cost_involved
    );
    uploadData.append(
      isEditEnabled ? "edited_return_to_work_id" : "return_to_work_id",
      return_to_work_id[0]["id"]
    );
    if (isEditEnabled) {
      uploadData.append("ira_reported_cost_detail_id", editId);
    }
    uploadData.append("file", files);
    const basePath = isEditEnabled
      ? "/listira/step3editcost"
      : "/listira/step3addcost";
    axios
      .post(Url.clientURL + basePath, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((responseJson) => {
        if (responseJson.status == 200) {
          if (responseJson.status == 200) {
            this.iraDetails();
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
    // fetch(Url.clientURL + "/listira/step3addcost", {
    //   method: 'post',
    //   body: uploadData,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // })
    //   .then(response => response.json())
    //   .then(responseJson => {

    //   })
    //   .catch(error => {
    //     throw error;
    //   }).finally(() => {
    //     this.manageDataGatheringParams("", {
    //     }, true);
    //   })
  };
  addOtherInfo = async () => {
    this.manageDataGatheringParams("analysis", {
      isLoading: true,
    });
    const { editId } = this.state.dataGathering.analysis;

    const isEditEnabled = editId !== "";
    let OtherInfomasterlist = this.state.OtherInfomasterlist.map(function (o) {
      let q = {
        questions_id: o.questions_id,
        response: o.response === null ? 0 : o.response,
        notes: o.notes,
      };
      return q;
    });
    let databody = {
      ...this.generateCommonParams(),
      userId: localStorage.getItem("userId"),
      other_info: OtherInfomasterlist,
    };
    const editPayload = {
      ...this.generateCommonParams(),
      other_info_master_id: editId,
      response: OtherInfomasterlist[0]?.response,
      notes: OtherInfomasterlist[0]?.notes,
    };
    let formBody = [];
    for (var property in editPayload) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(editPayload[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const content_type = !isEditEnabled
      ? "application/json"
      : "application/x-www-form-urlencoded";
    const url = isEditEnabled
      ? "/listira/step3editotherinfo"
      : "/listira/step3otherinfo";
    fetch(Url.clientURL + url, {
      method: "post",
      body: isEditEnabled ? formBody : JSON.stringify(databody),
      headers: {
        "Content-Type": content_type,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.iraDetails();
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.manageDataGatheringParams("", {}, true);
      });
  };

  deleteRecords = async () => {
    const { parameters, stepNo, url } = this.state.deleteParams;

    this.manageDataGatheringParams(
      "",
      {
        isLoading: true,
      },
      false,
      "deleteParams"
    );
    const details = {
      ...this.generateCommonParams(),
      ...parameters,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const prefixPath = url !== "" ? url : apiUrl[stepNo]?.delete;
    fetch(Url.clientURL + prefixPath, {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (refreshUrls.includes(url)) {
          window.location.reload();
          return;
        }
        this.iraDetails();
      })
      .finally(() => {
        if (stepNo === "step1") {
          this.resetIntialParams();
        }

        this.manageDataGatheringParams(
          "",
          {
            ...intialValue.deleteParams,
          },
          false,
          "deleteParams"
        );
      });
  };
  manageWitnessPayload = (el) => {
    this.manageDataGatheringParams("witness", {
      isEdit: el?.witness_id,
      isAddEnable: true,
      employee_contractor_status: el?.employee_contractor_status,
      contractor_company: el?.contractor_company,
      witness_name: el?.witness_name,
      witness_statement: el?.witness_statement,
      files: [el?.witness_pic[0]["witness_pic"]],
      witness_evidence_id: el?.witness_pic[0]?.pic_id,
    });
  };
  manageChoronologyPayload = (el) => {
    const { date, time, description, info_source } = el;
    this.manageDataGatheringParams("chronology", {
      isEdit: el?.id,
      isAddEnable: true,
      date: _momentDate(date),
      time,
      description,
      info_source,
    });
  };
  manageRootCausePayload = (el) => {
    const { date, time, description, info_source } = el;
    this.manageDataGatheringParams("chronology", {
      isEdit: el?.id,
      isAddEnable: true,
      date: _momentDate(date),
      time,
      description,
      info_source,
    });
  };
  // Set intial value i.e existing values
  _intialValue = async (data) => {
    const tempData = [...data.contributing_factor.main_category];
    const tempRootData = [...data.contributing_factor.root_cause_type];

    // Main category values
    if (tempData.length > 0) {
      // main categories
      tempData.forEach(async (item) => {
        await this._toggleMainCategoryCheck(item.main_category_id);

        // Sub categories
        if (item.sub_category && item.sub_category.length) {
          item.sub_category.forEach(async (subItem) => {
            await this._toggleSubCategoryCheck(
              item.main_category_id,
              subItem.sub_category_id
            );

            // major categories Array
            if (
              subItem.major_root_cause_category &&
              subItem.major_root_cause_category.length
            ) {
              subItem.major_root_cause_category.forEach(async (majorItem) => {
                await this._toggleMajorCategoryCheck(
                  item.main_category_id,
                  subItem.sub_category_id,
                  majorItem.major_root_cause_category_id
                );

                // Near root categories Array
                if (
                  majorItem.near_root_cause_category &&
                  majorItem.near_root_cause_category.length
                ) {
                  majorItem.near_root_cause_category.forEach(
                    async (nearItem) => {
                      await this._toggleNearCategoryCheck(
                        item.main_category_id,
                        subItem.sub_category_id,
                        majorItem.major_root_cause_category_id,
                        nearItem.near_root_cause_category_id
                      );

                      // Intermediate Array
                      if (
                        nearItem.intermediate_cause_category &&
                        nearItem.intermediate_cause_category.length
                      ) {
                        nearItem.intermediate_cause_category.forEach(
                          async (interItem) => {
                            await this._toggleInterCategoryCheck(
                              item.main_category_id,
                              subItem.sub_category_id,
                              majorItem.major_root_cause_category_id,
                              nearItem.near_root_cause_category_id,
                              interItem.intermediate_cause_category_id
                            );
                          }
                        );
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
    }

    // Root cause type values
    if (tempRootData.length > 0) {
      // Root cause type
      tempRootData.forEach(async (typeItem) => {
        await this._toggleRootCauseTypeCheck(typeItem.root_cause_type_id);
        // Root cause
        if (typeItem.root_cause && typeItem.root_cause.length) {
          typeItem.root_cause.forEach(async (rootItem) => {
            await this._toggleRootCauseCheck(
              typeItem.root_cause_type_id,
              rootItem.root_cause_id
            );
          });
        }
      });
    }
    this.manageDataGatheringParams("rootCause", {
      isAddEnable: true,
      editId: data.probable_cause.id,
      contributing_factor: data.contributing_factor.contributing_factor,
      probable_cause: data?.probable_cause?.probable_cause,
      description: data?.probable_cause?.description,
    });
  };
  mapInvesigationParams = (el) => {
    const injurtParts = el.joining_details.injured_parts?.map((el) =>
      el?.id?.toString()
    );
    this.manageDataGatheringParams("investigation", {
      isLoading: false,
      isImageProcessing: false,
      editId: el?.injured_person_id,
      proofType: el?.identity_proof?.length
        ? this.state.iraidentityProof.filter(
            (sub) => sub.id === el?.identity_proof[0]["category_id"].toString()
          )
        : [],
      isAddEnable: true,
      injured_person_age: el?.injured_person_age,
      injuredPersonType: this.state.injuredPersonList.length
        ? this.state.injuredPersonList?.filter(
            (sub) => sub.label === el?.injured_person_name
          )
        : "other",
      employee_contractor_status: el?.employee_type === "Employee" ? 1 : 2,
      injured_person_name: el?.injured_person_name ?? "",
      contractor_company_name: el?.contractor_company ?? "",
      OtherInfomasterlist: [],
      identity_proof: el?.identity_proof ?? [],
      person_work_type: this.state.iraWorkType.filter(
        (sub) => sub.id === el.person_work_type_id.toString()
      ),
      joining_date: _momentDate(el?.joining_date),
      month_with_this_emp: this.generateDropdownParameter(
        month.filter(
          (sub) => sub.id === el?.joining_details?.company_exp_month
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      year_with_this_emp: this.generateDropdownParameter(
        YearSelect.filter(
          (sub) => sub.id === el?.joining_details?.company_exp_year
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      month_doing_this_job: this.generateDropdownParameter(
        month.filter(
          (sub) => sub.id === el?.joining_details?.overall_company_exp_month
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      year_doing_this_job: this.generateDropdownParameter(
        YearSelect.filter(
          (sub) => sub.id === el?.joining_details?.overall_company_exp_year
        ),
        {
          id: "id",
          label: "name",
        }
      ),
      injured_person_gender: this.state.genderList.filter(
        (sub) => sub.label === el?.injured_person_gender
      ),
      injured_type_id: this.state.injurtType.filter(
        (sub) => sub.label === el?.injury_type
      ),
      time_of_incident: this.state.iraTimeOfIncident.filter(
        (sub) => sub.id === el.joining_details.time_of_incident_id.toString()
      ),
      working_shift: this.state.workingShift.filter(
        (sub) => sub.id === el.joining_details.working_shift_id.toString()
      ),
      injured_parts: this.state.iraInjuredParts.filter((sub) => {
        if (injurtParts.includes(sub.id)) {
          return sub;
        }
      }),
    });
    this.setState({
      questionArray: el?.incident_analysis_question,
    });
  };
  manageCostPayload = (el) => {
    if (Object.keys(el).length) {
      this.manageDataGatheringParams("cost", {
        editId: el?.ira_cost_involved_id,
        isAddEnable: true,
        cost_involved: el?.cost_involved,
        mandays_lost: el?.mandays_lost,
        return_to_work_id: this.state.returnToWorkList.filter(
          (sub) =>
            sub.id === el.return_to_work_list[0]?.return_to_work_id.toString()
        ),
        files: [],
      });
    }
  };

  filterQuestion = (question = [], arr = []) => {
    const tempSelected = arr.map((el) => el.question_id?.toString());
    const tempArry = [];
    question.map((el) => {
      if (!tempSelected.includes(el.id)) {
        tempArry.push(el);
      }
    });
    return tempArry;
  };
  getFileName = (url = "", type = 1) => {
    if (url !== "") {
      const strArr = url.split("/");
      const fileName = strArr[strArr.length - 1];
      const fileExtension = fileName.split(".")[1];
      if (type === 1) {
        return fileName;
      } else if (type === 2) {
        return fileExtension;
      }
      return [fileName, fileExtension];
    }
  };
  getAnalyisiData = (five_m_analysis_type) => {
    this.setState({
      isAnalysisDataFetching: true,
    });
    axios
      .post(
        Url.clientURL + "/listira/fivemanalysisfactorlist",
        qs.stringify({
          ...this.generateCommonParams(),
          five_m_analysis_type,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          analysisData: res.data.data.ira5MAnalysisFactors_list.map((el) => {
            return {
              ...el,
              isActive: false,
            };
          }),
          isAnalysisDataFetching: false,
        });
      })
      .catch((err) => console.log(err));
  };
  manage5mAnalytics = (e) => {
    if (this.state.reportStatus !== "completed") {
      const el = analysisType[e.target.outerText];
      if (el) {
        this.getAnalyisiData(el);
        this.setState({
          activeAnalysisItem: el,
          activeModule: e.target.outerText,
        });
      }
    }
  };
  manage5mPayload = (id) => {
    this.setState((prev) => {
      return {
        analysisData: prev?.analysisData.filter((el) => {
          if (el.factor_id === id) {
            el.isActive = !el.isActive;
          }
          return el;
        }),
      };
    });
  };
  save5MAnalysis = (five_m_analysis_type, arr) => {
    const sub_factor_id = [];
    arr.forEach((item) => {
      if (item.isActive) {
        sub_factor_id.push(item.factor_id);
      }
    });

    const data = {
      ...this.generateCommonParams(),
      five_m_analysis_type,
      sub_factor_id,
    };
    axios
      .post(
        Url.clientURL + "/listira/add5MAnalysisfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ activeAnalysisItem: null });
          this.iraDetails();
        }
      })
      .catch((err) => console.log(err));
  };
  save5MAnalysisDesc = () => {
    const data = {
      ...this.generateCommonParams(),
      ...this.state.activePopperParams,
      description: this.state.desc,
    };
    axios
      .post(
        Url.clientURL + "/listira/adddescription5mfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          AlertMessage({
            message: "Description saved successfully",
            type: "success",
          });
          this.setState({ activePopperParams: {}, desc: "" });
          this.iraDetails();
        }
      })
      .catch((err) => {
        AlertMessage({
          message: "Description not saved ",
          type: "error",
        });
      });
  };
  update5MAnalysisDesc = () => {
    this.setState((prev) => {
      return {
        activeEditPopperParams: {
          ...prev.activeEditPopperParams,
          isLoading: true,
        },
      };
    });

    const data = {
      ...this.generateCommonParams(),
      ...this.state.activeEditPopperParams,
      description: this.state.desc,
    };
    axios
      .post(
        Url.clientURL + "/listira/editdescription5mfactors",
        qs.stringify(data, { arrayFormat: "repeat" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          AlertMessage({
            message: "Description updated successfully",
            type: "success",
          });
          this.setState({
            activePopperParams: {},
            activeEditPopperParams: {
              isLoading: false,
              isEnable: false,
              five_m_analysis_type: "",
              sub_factor_id: null,
            },
            desc: "",
          });
          this.iraDetails();
        }
      })
      .catch((err) => {
        AlertMessage({
          message: "Description not updated ",
          type: "error",
        });
      });
  };
  getData = (obj = {}, key = "") => {
    if (Object.keys(obj).length) {
      return obj[analysisListType[key]];
    }
  };
  deleteAnalyisiData = () => {
    this.setState({
      isFactorLoading: true,
    });
    axios
      .post(
        Url.clientURL + "/listira/delete5MAnalysisfactors",
        qs.stringify({
          ...this.generateCommonParams(),
          ...this.state.factorParams,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.iraDetails();
          this.getAnalyisiData(this.state.factorParams.five_m_analysis_type);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFactorLoading: false,
          factorParams: {},
        });
      });
  };

  renderPopoverContent = (arr = [], isReduced = false, isEditEnabled) => {
    const minWidth = isReduced ? "9vw" : "15vw";
    return (
      <div className="">
        <Scrollbars
          style={{
            height: "5vw",
            maxWidth: "15vw",
            minWidth,
          }}
          renderView={(props) => (
            <div
              style={{
                paddingRight: "15px",
                paddingBottom: "1rem",
                ...props.style,
                overflowX: "hidden",
              }}
            />
          )}
        >
          <div className=" d-flex flex-column mr-3">
            {arr?.map((el, idx) => {
              const { activePopperParams = {}, activeEditPopperParams } =
                this.state ?? {};
              const { five_m_analysis_type = "", sub_factor_id = "" } =
                activePopperParams ?? {};
              const { isLoading = false, isEnable = false } =
                activeEditPopperParams ?? {};
              {
              }
              const { factor_description = "" } = el ?? {};
              return (
                <SLDPopover
                  isOpen={
                    five_m_analysis_type === el?.analysis_type &&
                    sub_factor_id === el?.factor_id
                  }
                  body={
                    <>
                      {factor_description !== "" && !isEnable ? (
                        <div>
                          <span>{factor_description}</span>
                        </div>
                      ) : this.state.reportStatus !== "completed" ? (
                        <Textarea
                          aria-describedby="error-1"
                          id="text-area-error"
                          name="required-textarea-error"
                          label={`${isEnable ? "Update" : "Add"} Description`}
                          placeholder="Enter description"
                          value={this.state.desc}
                          disabled={
                            this.state.reportStatus === "completed" ||
                            !isEditEnabled
                          }
                          onChange={(e) =>
                            this.setState({
                              desc: e.target.value,
                            })
                          }
                        />
                      ) : (
                        "No Description Found"
                      )}
                    </>
                  }
                  key={idx}
                  footer={
                    <div className="slds-text-align_right">
                      <SLDButton
                        label={
                          factor_description === "" &&
                          this.state.reportStatus !== "completed"
                            ? "Cancel"
                            : "Close"
                        }
                        // disabled={this.state.reportStatus === "completed"}
                        onClick={() =>
                          this.debounceFunc(() => {
                            this.setState({
                              activePopperParams: {},
                              desc: "",
                              activeEditPopperParams: {
                                isLoading: false,
                                isEnable: false,
                                five_m_analysis_type: "",
                                sub_factor_id: null,
                              },
                            });
                          })
                        }
                      />
                      {factor_description === "" &&
                      this.state.reportStatus !== "completed" ? (
                        <SLDButton
                          variant="brand"
                          label="Save"
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.save5MAnalysisDesc();
                            })
                          }
                          disabled={this.state.desc === ""}
                        />
                      ) : null}
                      {isEnable ? (
                        <SLDButton
                          variant="brand"
                          label={isLoading ? "Updating..." : "Update"}
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.update5MAnalysisDesc();
                            })
                          }
                          disabled={this.state.desc === ""}
                        />
                      ) : null}
                      {factor_description !== "" &&
                      !isEnable &&
                      isEditEnabled ? (
                        <SLDButton
                          variant="brand"
                          label="Edit"
                          disabled={this.state.reportStatus === "completed"}
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.setState({
                                activeEditPopperParams: {
                                  isEnable: true,
                                  five_m_analysis_type,
                                  sub_factor_id,
                                },
                                desc: factor_description,
                              });
                            })
                          }
                        />
                      ) : null}
                    </div>
                  }
                  heading="Description"
                  id="popover-controlled-with-footer"
                  position="overflowBoundaryElement"
                >
                  <span
                    className={`badge border p-2  m-2 clide-v2-secondary-color  cursor-p`}
                    disabled={this.state.reportStatus === "completed"}
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.setState({
                          activePopperParams: {
                            five_m_analysis_type: el?.analysis_type,
                            sub_factor_id: el?.factor_id,
                          },
                        });
                      })
                    }
                  >
                    {el?.factor}
                  </span>
                </SLDPopover>
              );
            })}
          </div>
        </Scrollbars>
      </div>
    );
  };
  manageFishBoneChart = (fishData, obj = {}, isEditEnabled = false) => {
    let temp = {};
    Object.entries(fishData?.Excavation).forEach((el) => {
      const [key] = el;
      const activeKey = analysisContent[key];
      console.log("5m array", obj);
      if (Object.keys(obj).length) {
        const isWidthReducedEnabled = !["Management", "Machinery"].includes(
          key
        );
        temp = {
          ...temp,
          [key]: [
            this.renderPopoverContent(
              obj[activeKey],
              isWidthReducedEnabled,
              isEditEnabled
            ),
          ],
        };
      }
    });
    return {
      Excavation: {
        ...temp,
      },
    };
  };
  manageJobAnalysisEdit = (el) => {
    this.manageDataGatheringParams("analysis", {
      isAddEnable: true,
      editId: el?.questions_id,
    });
    this.setState({
      OtherInfomasterlist: [
        {
          ...el,
          response: el.response_type,
        },
      ],
    });
  };
  assetRender = (url, filType = "url") => {
    const [name, type] = this.getFileName(url, 0);
    return (
      <div>
        <div class="slds-file slds-file_card slds-has-title">
          <figure>
            <a class="slds-file__crop">
              <span class="slds-assistive-text">Preview:</span>
              <img
                src={
                  type !== "pdf"
                    ? url
                    : require("../../../../assets/sls-icons/pdfIcon.svg")
                }
                alt="Description of the image"
              />
            </a>
            <figcaption class="slds-file__title slds-file__title_card">
              <div class="slds-media slds-media_small slds-media_center">
                <div class="slds-media__figure slds-line-height_reset user-icon mr-1">
                  <span class="slds-icon_container" title="pdf">
                    <img
                      src={require(type !== "pdf"
                        ? "../../../../assets/sls-icons/imageIcon.svg"
                        : "../../../../assets/sls-icons/pdfIcon.svg")}
                      className="clide-v2-profile-img"
                    />
                  </span>
                </div>
                <div class="slds-media__body d-flex">
                  <span class="slds-file__text slds-truncate" title="File">
                    {name}
                  </span>
                  <Eye
                    className="cursor-pointer ml-1 w-50"
                    onClick={() => {
                      this.setState({
                        docUrl: url,
                      });
                    }}
                  />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    );
  };
  getInjuredParts = (el) => {
    const temp = [];
    const { injured_parts } = el?.joining_details;

    injured_parts.forEach((el) => {
      temp.push(el?.injured_parts?.toLowerCase());
    });
    return temp;
  };
  _removeFactor = (factorId, sub_factor_id, callBack) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    this.setState({ isFactorLoading: true });
    const project_id = this.state.iraBasicInfo.project_id;
    const service_id = this.state.iraBasicInfo.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      factor_id: sub_factor_id,
      sub_factor_id: factorId,
      module_unique_id: this.state.iraId,
      module_name: "ira",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/deletefactors", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.iraDetails();
          this._InProgressContributingList();
          this._InProgressImmediateList();
          this._InProgressRcaList();
          this.resetFactorsState(this.toggleStep2Fun);
          this.hideModal();
        }
      })
      .finally(() => {
        callBack(sub_factor_id);
      });
  };
  manageFactors = (data) => {
    const { factorID, formType } = data ?? {};
    this.setState(
      {
        isFactorLoading: true,
      },
      this._removeFactor(factorID, formType, (id) => {
        this.setState({
          isFactorLoading: false,
          rootfactorParams: {},
        });
      })
    );
  };
  _InProgressFactorAddList = async (factorId) => {
    const { iraBasicInfo, iraId, CFList, IFList, RCAFList } = this.state;
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
  
    // Set loading state
    this.setState({ isFactorLoading: true });
  
    // Retrieve project, service, and module IDs
    const { project_id, service_id } = iraBasicInfo;
    const near_miss_id = iraId;
  
    // Map factorId to corresponding factor list
    const factorLists = {
      1: CFList?.factors_list,
      2: IFList?.factors_list,
      3: RCAFList?.factors_list,
    };
    
    const factorList = factorLists[factorId]?.filter(el => el?.checked)?.map(el => el?.factor_id) || [];
    console.log(factorList);
  
    // Prepare data for API request
    const data = {
      auth_key: token,
      user_id: userId,
      service_id,
      project_id,
      module_unique_id: near_miss_id,
      factor_id: factorId,
      sub_factor_id: factorList,
      module_name: "ira",
      custom_cf: [],
    };
    // await this.setModalVisible(false);
    if (factorList.length) {
      fetch(Url.clientURL + "/rootcause/addfactors", {
        method: "post",
        body: qs.stringify(data, { arrayFormat: "repeat" }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson.status == 200) {
            console.log("sayan 200");
            this.resetFactorsState(this.toggleStep2Fun);
            this.iraDetails();
          } else {
          }
        })
        .catch((error) => {
          console.log("sayan err");
        })
        .finally(() => {
          this.setState({ isFactorLoading: false });
        });
    } else {
      this.toggleStep2Fun();
    }
  };

  handleCheckboxChangeIF = (event) => {
    const { factors_list } = this.state?.IFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      IFList: {
        ...prevState.IFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChangeRAC = (event) => {
    const { factors_list } = this.state?.RCAFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      RCAFList: {
        ...prevState.RCAFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChange = (event) => {
    const { factors_list } = this.state?.CFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      CFList: {
        ...prevState.CFList,
        factors_list: updatedFactorsList,
      },
    }));
  };
  hideModal = () => {
    this.setState({ toggleStep2: false });
  };

  validateStep2Fields = () => {
    const { investigation } = this.state.dataGathering;
    const errorFieldNames = [];
    if (investigation?.editId === "") {
      if (
        investigation.proofType === "" ||
        investigation.identity_proof.length === 0
      ) {
        errorFieldNames.push("isIdProofTypeError");
        this.setState({
          stepValidations: {
            ...this.state.stepValidations,
            isIdProofTypeError: investigation.proofType === "",
            isIdProofDocumentError: investigation.identity_proof.length === 0,
          },
        });
      } else {
        this.setState({
          stepValidations: {
            isIdProofTypeError: false,
            isIdProofDocumentError: false,
          },
        });
      }
    }

    return errorFieldNames;
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
  }

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={165}
          cutout={"75%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);
  render() {
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const isCompleted = this.state.reportStatus === "completed";
    const isInprogress = this.state.reportStatus === "progress";
    const isRootCauseTabsDisabled = !_.isUndefined(
      this.state.iraDetail?.step_4_root_cause_option_selected
    );
    console.log("isRootCauseTabsDisabled", isRootCauseTabsDisabled);

    const {
      dataGathering,
      categoryList,
      iraWorkType,
      iraInjuredParts,
      masterList,
      deleteParams,
      iraId,
      userData,
    } = this.state;
    const {
      investigation = {},
      witness = {},
      posts = {},
      analysis = {},
      recommendation = {},
      filterParams = {},
      chronology = {},
      rootCause = {},
      cost = {},
    } = dataGathering ?? {};

    console.log("filterParams", filterParams);
    const {
      iraBasicInfo,
      iraDetail,
      iraDetailStep5,
      iraDetailStep1,
      iraDetailStep2,
      iraDetailStep3,
      iraDetailStep4,
      iraCache,
    } = this.state;
    const { costList = [] } = iraDetailStep3?.cost_details ?? {};
    const capaMinDate = !_.isUndefined(iraBasicInfo.target_date)
      ? getNextDays(_momentDate(iraBasicInfo.target_date))
      : new Date();
    const teamLead =
      iraBasicInfo?.people_involved_list?.filter((el) => el.status === 1)[0] ??
      [];
    const isTreeAvailable =
      iraDetailStep4?.contributing_factor?.main_category?.length;

    const isReportedByEnabled =
      userData?.user_id === iraBasicInfo?.reported_by_id;
    const accountsWithIcon = iraInjuredParts?.length
      ? iraInjuredParts?.map((elem) => ({
          ...elem,
          ...{
            icon: (
              <Icon
                assistiveText={{ label: "Account" }}
                category="standard"
                name={"account"}
              />
            ),
          },
        }))
      : [];
    const isTeamMemeberLoggedIn = Boolean(
      searchInArrary(iraBasicInfo?.people_involved_list, {
        key: "user_id",
        value: userData?.user_id,
      })
    );

    const isApproveEnabled =
      userData?.user_id === teamLead?.user_id ||
      ["3", "1", "5"].includes(userData?.designation_flag?.toString());
    const list =
      this.state.currentTab === "prop"
        ? iraCache?.praposed_corrective_action
        : iraCache?.final_corrective_action ?? [];

    const recommendedby = _.unionBy(
      list?.map((el) => {
        return {
          recommendedBy: el?.recommended_by,
          recommended_id: el?.recommended_by_id,
        };
      }),
      "recommended_id"
    );
    const isCrownEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    const isDeleteEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;

    if (this.state.imageType && this.state.imageType == "evidence") {
      sourcesList = iraBasicInfo.evidence_images?.map(
        (item) => item.evidence_image
      );
      sourcesType = iraBasicInfo.evidence_images?.map(
        (item) => item.evidence_format
      );
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/ira/dashboard`} />;
    }

    let uaUcChartData = [];
    if (iraBasicInfo?.analysis != undefined) {
      uaUcChartData = [
        {
          y: iraBasicInfo.analysis?.unsafe_act,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: iraBasicInfo.analysis?.unsafe_condition,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.unsafe_act,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: iraBasicInfo.analysis?.unsafe_condition,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let nmuaUcChartData = [];

    if (iraBasicInfo?.analysis != undefined) {
      nmuaUcChartData = [
        {
          y: iraBasicInfo.analysis?.nm_ua_number,
          name: "Unsafe Act",
          color: iraBasicInfo.analysis?.nm_ua_color,
        },
        {
          y: iraBasicInfo.analysis?.nm_uc_number,
          name: "Unsafe Condition",
          color: iraBasicInfo.analysis?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: iraBasicInfo.analysis?.nm_ua_color,
            },
            {
              y: iraBasicInfo.analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: iraBasicInfo.analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let analysisChartData = [];
    if (iraBasicInfo?.analysis != undefined) {
      analysisChartData = [
        {
          y: iraBasicInfo.analysis?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: iraBasicInfo.analysis?.rta_number ?? 0,
          name: "RTA",
          color: "#FAAC15",
        },
        {
          y: iraBasicInfo.analysis?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: iraBasicInfo.analysis?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: iraBasicInfo.analysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: iraBasicInfo.analysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: iraBasicInfo.analysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: iraBasicInfo.analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: iraBasicInfo.analysis?.mtc_number || 0,
              name: "MTC",
              color: "#086992",
            },
            {
              y: iraBasicInfo.analysis?.tra_number || 0,
              name: "RTA",
              color: "#FAAC15",
            },
            {
              y: iraBasicInfo.analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
            {
              y: iraBasicInfo.analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: iraBasicInfo.analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
            {
              y: iraBasicInfo.analysis?.notable_number || 0,
              name: "Notable",
              color: "rgb(161, 255, 206)",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //days
    const Newdays = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.completioncount.map(
            (data) => data.capa_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.completioncount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.completioncount.map(
              (data) => data.capa_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //CAPA
    const CapaCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.capacount.map(
            (data) => data.report_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.capacount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.capacount.map(
              (data) => data.report_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Open
    const OpenCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.opencount.map((data) => data.recommendation_open_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.opencount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.opencount.map((data) => data.recommendation_open_count),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const CloseCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.closecount.map((data) => data.recommendation_close_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.closecount.map((data) => data.color),
      series: [
        {
          // data: [this.state.userFormView.basic_info?.total_score],
          data: [
            this.state.closecount.map(
              (data) => data.recommendation_close_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //GP Count
    const gpCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingInternalCount.map(
            (data) => data.training_internal_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount.map(
              (data) => data.training_internal_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          //  this.state.trainingExternalCount.map((data) => data.training_external_count ? data.training_external_count : 0)[0]  +
          this.state.trainingExternalCount.map((data) =>
            data.training_external_count ? data.training_external_count : 0
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingExternalCount.map(
              (data) => data.training_external_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2vw" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    if (this.state?.isDetailsLoadingFirstTime) {
      return (
        <div className="m-4">
          <ClideLoader />
        </div>
      );
    }

    const hTriangleChart = {
      chart: {
        type: "pyramid",
      },
      colors: ["#206EB6", "#45B5F4", "#DBFF00", "#FF9900", "#FF0000"],
      credits: {
        enabled: false,
      },
      title: {
        text: "H T Present",
        x: -50,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: true,
          },
          center: ["42%", "50%"],
          width: "60%",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "<b>{point.y}</b>",
        shared: true,
        backgroundColor: "#FFFFFF",
        borderColor: "#EBEBEB",
        borderRadius: 10,
      },
      series: [
        {
          data: [
            ["UA/UC", iraBasicInfo.analysis?.unsafe_act+iraBasicInfo.analysis?.unsafe_condition],
            ["NM", iraBasicInfo.analysis?.nm_ua_number+iraBasicInfo.analysis?.nm_uc_number],
            ["Notable",  iraBasicInfo.analysis?.notable_number],
            ["FIrst Aid", iraBasicInfo.analysis?.first_aid_number],
            ["OI",  iraBasicInfo.analysis?.occupational_illness_number],
            ["LTI",  iraBasicInfo.analysis?.lti_number],
            ["TRA",  iraBasicInfo.analysis?.tra_number],
            ["MTC", iraBasicInfo.analysis?.mtc_number],
            ["FA", iraBasicInfo.analysis?.fa_number],
          ],
        },
      ],
    };

    const variablesObj = {
      isCrownEnabled,
      isDeleteEnabled,
      isInprogress,
      isApproveEnabled,
      iraDetailStep1,
      iraDetailStep2,
      iraDetailStep3,
      iraDetailStep4,
      iraDetailStep5,
      dataGathering,
      accountsWithIcon,
      isTeamMemeberLoggedIn,
      isCompleted,
      isRootCauseTabsDisabled,
      isTreeAvailable,
      iraId,
      iraDetail:this.state.iraDetail
    };
    const chartsVariableObj = {
      analysisChart,
      analysisChartData,
      nmuaUcChartData,
      nmuaUcChart,
      uaUcChartData,
      uaUcChart,
      gpCount,
      tbtCount,
      internalCount,
      externalCount,
      employeeCount,
      contractorCount,
      hTriangleChart,
    };
    return (
      <div className="clide-v2-pe-5 clide-v2-ps-5 container-fluid mb-5 pe-5 ps-5">
        <div className="row  clide-v2-colume_title ">
          {" "}
          <div className="mb-4 clide-v2-fs-3 fw-bold">IRA Detail</div>
          <DetailTimelineSection
            data={this.renderTimeLine(iraBasicInfo, this.state)}
            functionContent={this}
            variablesObj={variablesObj}
            state={this.state}
            
          />
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-9 col-lg-9">
            <DetailLeftSection
              state={this.state}
              functionContent={{
                ...this,
                setState: this.setState,
                toggleStep2Fun: this.toggleStep2Fun,
              }}
              variablesObj={variablesObj}
            />
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <DetailRightSection
              state={this.state}
              functionContent={this}
              variablesObj={variablesObj}
              chartsVariableObj={chartsVariableObj}
            />
          </div>
        </div>
        <>
          <Modal
            show={this.state.userLikedPopup}
            onHide={this.handleClose}
            className="likeduser"
          >
            <Modal.Header closeButton>
              <Modal.Title>User Who Liked</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="totalLikedUser">
                <span>All</span>
                <span className="count">{this.state.likeNumber}</span>
              </div>
              {this.state.userLike != "" ? (
                this.state.userLike.map((items, index) => (
                  <div className="user-list" key={index}>
                    <img src={items.user_profile} alt={items.user_name} />
                    <h6 className="user-name">{items.user_name}</h6>
                  </div>
                ))
              ) : (
                <div className="user-list">
                  <p className="data-not-present">Likes not available </p>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Modal show={this.state.teamLeadID !== null} className="likeduser">
            {/* <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header> */}
            <Modal.Body>
              <div className=" shadow-none ">
                <div className="d-flex space-between">
                  <div className="rd-box-head1 text-center">
                    <h5 className="font-weight-500 common-black-1 clide-v2-primary-color font-weight-bold">
                      Select Due Date
                    </h5>
                  </div>
                  {isInprogress && (
                    <div>
                      <span className="font-weight-light font-16">
                        Previous Due Date : {iraBasicInfo.target_date}
                      </span>
                    </div>
                  )}
                </div>
                <Col style={{ margin: "1rem", width: "80%" }}>
                  <DatePicker
                    selected={this.state.selectedTargetDate}
                    minDate={new Date()}
                    placeholderText="Due date"
                    inputStyle={{ textAlign: "center" }}
                    onChange={(jsDate) =>
                      this._handleChange(jsDate, "target_date")
                    }
                    popperProps={{
                      positionFixed: true // use this to make the popper position: fixed
                    }}
                  />
                </Col>

                <Col className="text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-danger font-weight-500"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.confirmSubmitTargetDate();
                      })
                    }
                  >
                    Confirm
                  </button>
                </Col>
              </div>
            </Modal.Body>
          </Modal>
          <Modal centered show={recommendation?.isAddEnable}>
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-white-2 clide-v2-primary-color font-weight-bold">
                    {" "}
                    {recommendation.editId !== "" ? "EDIT" : "Add"} CAPA
                  </span>
                </div>
              </div>
              <div className="align-items-center d-flex modal-controls">
                <button
                  //  onClick={() => this.manageDataGatheringParams("", {}, true)}
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.manageDataGatheringParams("", {}, true);
                      this.setState({
                        priority: {
                          extreme: false,
                          high: false,
                          medium: false,
                          low: false,
                        },
                        capaType: "",
                        iAssignErrorEnabled: false,
                        isCategoryErrorEnabled: false,
                        isCorretiveActionErrorEnabled: false,
                        isCAPAErrorEnabled: false,
                        isDueDateErrorEnabled: false,
                        isPriortiyErrorEnabled: false,
                      });
                    })
                  }

                  // onClick={() => {
                  //   this.manageDataGatheringParams("", {}, true);
                  //   this.setState({
                  //     priority: {
                  //       extreme: false,
                  //       high: false,
                  //       medium: false,
                  //       low: false,
                  //     },
                  //     capaType: "",
                  //     iAssignErrorEnabled: false,
                  //     isCategoryErrorEnabled: false,
                  //     isCorretiveActionErrorEnabled: false,
                  //     isCAPAErrorEnabled: false,
                  //     isDueDateErrorEnabled: false,
                  //     isPriortiyErrorEnabled: false,
                  //   });
                  // }}
                >
                  Close
                </button>
                <button
                  disabled={recommendation.isLoading}
                  onClick={(e) => {
                    if (e.detail == 1) {
                      recommendation.editId !== ""
                        ? this._EditRecommendation()
                        : this._AddRecommendation();
                    }
                  }}
                >
                  {recommendation.isLoading ? (
                    <RefreshCw className="rotate-360" />
                  ) : recommendation.editId !== "" ? (
                    "EDIT"
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        this.manageDataGatheringParams("recommendation", {
                          category_l: selection,
                        });
                      },
                    }}
                    required
                    labels={{
                      label: "Select Category",

                      placeholderReadOnly: "Choose",
                    }}
                    options={this.generateDropdownParameter(
                      categoryList.data.category,
                      {
                        id: "category_id",
                        label: "category",
                      }
                    )}
                    selection={recommendation.category_l}
                    variant="readonly"
                    errorText={
                      this.state.isCategoryErrorEnabled
                        ? "Please choose option"
                        : null
                    }
                  />

                  <Textarea
                    aria-describedby="error-1"
                    id="text-area-error"
                    name="required-textarea-error"
                    label={
                      <div
                        className={
                          this.state.isCAPAErrorEnabled
                            ? "slds-has-error my-0"
                            : ""
                        }
                      >
                        <span
                          className="required"
                          style={{ color: "red", paddingLeft: "4px" }}
                          title="required"
                        >
                          *
                        </span>
                        <span
                          className="required"
                          style={{ paddingLeft: "4px" }}
                        >
                          CAPA
                        </span>

                        <div className="control1 common-check-box-layout d-flex">
                          <input
                            required
                            type="checkbox"
                            id="CA"
                            checked={this.state.capaType === "CA"}
                            onChange={() => this.setState({ capaType: "CA" })}
                            name="CA"
                            value="CA"
                          />
                          <label htmlFor="Priority1" className="mr-3">
                            {" "}
                            CA
                          </label>
                          <input
                            type="checkbox"
                            id="PA"
                            checked={this.state.capaType === "PA"}
                            onChange={() => this.setState({ capaType: "PA" })}
                            name="PA"
                            value="PA"
                          />
                          <label htmlFor="Priority2"> PA</label>
                        </div>
                        {this.state.isCAPAErrorEnabled ? (
                          <div id="lfzisi1cXH" class="slds-form-element__help">
                            Please select capa type
                          </div>
                        ) : null}
                      </div>
                    }
                    placeholder="Enter Corrective Action"
                    value={recommendation.correctiveActionText}
                    errorText={
                      this.state.isCorretiveActionErrorEnabled
                        ? "Please enter action"
                        : null
                    }
                    onChange={(e) =>
                      this.manageDataGatheringParams("recommendation", {
                        correctiveActionText: e.target.value,
                      })
                    }
                  />
                  <Combobox
                    id="combobox-readonly-single-repsonable"
                    required
                    events={{
                      onSelect: (event, { selection }) => {
                        this.manageDataGatheringParams("recommendation", {
                          responsible_p: selection,
                        });
                      },
                    }}
                    labels={{
                      label: "Assigned To",
                      placeholder: "Choose option",
                    }}
                    options={this.generateDropdownParameter(
                      categoryList.data.responsible_person,
                      {
                        id: "person_id",
                        label: "person",
                      }
                    )}
                    selection={recommendation.responsible_p}
                    // value={recommendation.responsible_p.label}
                    variant="readonly"
                    errorText={
                      this.state.iAssignErrorEnabled
                        ? "Please choose option"
                        : null
                    }
                  />
                  <div className="with-mic required">
                    {/* <span className="required" style={{ color: 'red', paddingLeft: '4px' }} title="required">*</span>
<span className="required" style={{ paddingLeft: '4px', fontSize:"9px" }}>Due Date</span> */}
                    <span
                      className="required"
                      style={{ color: "red", paddingLeft: "4px" }}
                      title="required"
                    >
                      *
                    </span>
                    <span
                      className="required"
                      style={{ paddingLeft: "4px", fontSize: "12px" }}
                    >
                      Due Date
                    </span>
                  </div>

                  <div
                    className={
                      this.state.isDueDateErrorEnabled ? "slds-has-error" : ""
                    }
                  >
                    <DatePicker
                      selected={recommendation.selectedDate}
                      placeholderText="Due Date"
                      inputStyle={{ textAlign: "center" }}
                      minDate={capaMinDate}
                      onChange={(jsDate) =>
                        this.manageDataGatheringParams("recommendation", {
                          selectedDate: jsDate,
                        })
                      }
                    />
                    {this.state.isDueDateErrorEnabled ? (
                      <div id="lfzisi1cXH" class="slds-form-element__help">
                        Please select date
                      </div>
                    ) : null}
                  </div>

                  <div className="with-mic ">
                    <span
                      className="required"
                      style={{ color: "red", paddingLeft: "4px" }}
                      title="required"
                    >
                      *
                    </span>
                    <span
                      className="required"
                      style={{ paddingLeft: "4px", fontSize: "12px" }}
                    >
                      Priority
                    </span>
                  </div>
                  <div
                    className={
                      this.state.isPriortiyErrorEnabled
                        ? "slds-has-error  "
                        : ""
                    }
                  >
                    <div className={"d-flex"}>
                      <Checkbox
                        assistiveText={{
                          label: "Extreme",
                        }}
                        labels={{
                          label: "Critical",
                        }}
                        id="Priority"
                        checked={this.state.priority.extreme}
                        onChange={() => this.onPriorityChange(0)}
                        name="Priority1"
                        value="Extreme"
                      />
                      <Checkbox
                        assistiveText={{
                          label: "High",
                        }}
                        labels={{
                          label: "Urgent",
                        }}
                        id="High"
                        checked={this.state.priority.high}
                        onChange={() => this.onPriorityChange(1)}
                        name="Priority2"
                        value="High"
                      />
                      <Checkbox
                        assistiveText={{
                          label: "Medium",
                        }}
                        labels={{
                          label: "Important",
                        }}
                        id="Medium"
                        checked={this.state.priority.medium}
                        onChange={() => this.onPriorityChange(2)}
                        name="Priority3"
                        value="High"
                      />
                      <Checkbox
                        assistiveText={{
                          label: "Low",
                        }}
                        labels={{
                          label: "Normal",
                        }}
                        id="Low"
                        checked={this.state.priority.low}
                        onChange={() => this.onPriorityChange(3)}
                        name="Priority3"
                        value="Low"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={this.state.deletePopup}
            className="submit-modal"
          >
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body className="p-0">
              <img
                className="submit-img"
                src={require("assets/images/delete-popup.svg")}
              />
              <p className="submit-text">
                Are you sure you want to delete this?
              </p>
              <div className="modal-footer-btn my-3">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={this.hideModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.deleteReport(this.state.iraId);
                    })
                  }
                >
                  YES, DELETE
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            show={this.state.confirmPopup}
            className="submit-modal"
          >
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body className="p-0">
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
              />
              <p className="submit-text">Click Confirm to freeze target date</p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={this.hideModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.submitTargetDate();
                    })
                  }
                >
                  CONFIRM
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            show={this.state.afterTargetDatePopup}
            className="submit-modal"
          >
            <Modal.Header
              closeButton
              onClick={() => {
                this.setState({
                  afterTargetDatePopup: false,
                });
              }}
            ></Modal.Header>

            <Modal.Body className="p-0">
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
              />
              <p className="submit-text">
                After adding target date this report will move in Inprogress
                list
              </p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() => {
                    this.setState({
                      afterTargetDatePopup: false,
                    });
                  }}
                >
                  CLOSE
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            show={this.state.isFreezeEnabled}
            
            className="submit-modal"
          >
            <Modal.Header
              onClick={() => {
                this.setState({
                  isFreezeEnabled: false,
                });
              }}
            ></Modal.Header>

            <Modal.Body className="px-0">
              <p className="submit-text">
                Are you sure you want to freeze all final corrective action
              </p>
              {recommendation?.isLoading ? (
                <div className="mx-2">
                  <Spinner />
                </div>
              ) : (
                <div className="modal-footer-btn my-3">
                  <button
                    type="submit"
                    className="clide-v2-primary clide-v2-white-color btn"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.finalRecordFreeze();
                      })
                    }
                  >
                    Freeze All
                  </button>
                  <button
                    type="submit"
                    className="btn border"
                    onClick={() => {
                      this.setState({
                        isFreezeEnabled: false,
                      });
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={recommendation?.approveId !== ""}
            
            className="submit-modal"
          >
            <Modal.Header></Modal.Header>

            <Modal.Body className="text-center p-0">
              <img
                className="submit-img"
                src={require("assets/images/delete-popup.svg")}
              />
              <p className="submit-text font-18">
                Are you sure you want to Approve this?
              </p>
              <div className="modal-footer-btn my-4">
                {recommendation?.isLoading ? (
                  <div className="m-3">
                    <Spinner />{" "}
                  </div>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn border"
                      onClick={() =>
                        this.manageDataGatheringParams("recommendation", {
                          approveId: "",
                        })
                      }
                    >
                      CANCEL
                    </button>{" "}
                    <button
                      type="submit"
                      className="btn clide-v2-primary clide-v2-white-color"
                      onClick={() =>
                        this.debounceFunc(() => {
                          this.approveRecommendation(recommendation?.approveId);
                        })
                      }
                    >
                      YES, APPROVE
                    </button>
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={recommendation?.deleteId !== ""}
            className="submit-modal"
          >
            <Modal.Header></Modal.Header>

            <Modal.Body>
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
              />
              <p className="submit-text">
                {recommendation?.isPropEnable
                  ? "Do you really want to delete this?"
                  : " If you Delete this Corrective Action from here it will be store in Proposed Corrective Action"}
              </p>
              <div className="modal-footer-btn">
                {recommendation?.isLoading ? (
                  <div className="m-3">
                    <Spinner />{" "}
                  </div>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.manageDataGatheringParams("recommendation", {
                          deleteId: "",
                        })
                      }
                    >
                      CLOSE
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() =>
                        this.debounceFunc(() => {
                          recommendation?.isPropEnable
                            ? this.removePropRecord(recommendation?.deleteId)
                            : this.removeFinalRecord(recommendation?.deleteId);
                        })
                      }
                      // onClick={() =>
                      //   recommendation?.isPropEnable
                      //     ? this.removePropRecord(recommendation?.deleteId)
                      //     : this.removeFinalRecord(recommendation?.deleteId)
                      // }
                    >
                      YES,DELETE
                    </button>
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={filterParams?.isFilterEnable ?? false}
            
            onHide={() => {
              this.manageDataGatheringParams("filterParams", {
                isLoading: false,
                isFilterEnable: false,
              });
            }}
          >
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-white-2 clide-v2-primary-color font-weight-bold">
                    {" "}
                    Filter
                  </span>
                </div>
              </div>
              <div className="d-flex  align-items-center">
                <div className="modal-controls">
                  <button
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.manageDataGatheringParams("", {}, true);
                        this.iraDetails();
                        this.setState({
                          extremeSelect: 0,
                          extremeValue: 0,
                          highSelect: 0,
                          mediumSelect: 0,
                          lowSelect: 0,
                        });
                      })
                    }
                    // onClick={() => {
                    //   this.manageDataGatheringParams("", {}, true);
                    //   this.iraDetails();
                    //   this.setState({
                    //     extremeSelect: 0,
                    //     extremeValue: 0,
                    //     highSelect: 0,
                    //     mediumSelect: 0,
                    //     lowSelect: 0,
                    //   });
                    // }}
                  >
                    Reset
                  </button>
                  <button
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.makeFilterList();
                      })
                    }
                  >
                    {filterParams.isLoading ? (
                      <RefreshCw className="rotate-360" />
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
                <X
                  style={{
                    height: "1.4vw",
                    width: "1.4vw",
                  }}
                  className="cursor-pointer mx-2 common-white-2"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.manageDataGatheringParams("", {}, true);
                    })
                  }
                />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        this.manageDataGatheringParams("filterParams", {
                          category: selection,
                        });
                      },
                    }}
                    labels={{
                      label: " Select Category",
                      placeholder: "Choose Option",
                    }}
                    options={this.generateDropdownParameter(
                      categoryList.data.category,
                      {
                        id: "category_id",
                        label: "category",
                      }
                    )}
                    selection={filterParams.category}
                    variant="readonly"
                  />
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        this.manageDataGatheringParams("filterParams", {
                          recommendation_by: selection,
                        });
                      },
                    }}
                    labels={{
                      label: "Corrective Action Proposed By",
                      placeholder: "Select  Category",
                    }}
                    options={this.generateDropdownParameter(recommendedby, {
                      id: "recommended_id",
                      label: "recommendedBy",
                    })}
                    selection={filterParams.recommendation_by}
                    variant="readonly"
                  />
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        this.manageDataGatheringParams("filterParams", {
                          responsible_person_id: selection,
                        });
                      },
                    }}
                    labels={{
                      label: " Assigned To",
                      placeholder: "Choose option",
                    }}
                    options={this.generateDropdownParameter(
                      categoryList.data.responsible_person,
                      {
                        id: "person_id",
                        label: "person",
                      }
                    )}
                    selection={filterParams.responsible_person_id}
                    variant="readonly"
                  />
                  <div className="d-flex space-between">
                    <div className="mr-4 w-50">
                      <div className="with-mic ">
                        <label className="slds-form-element__label">
                          Start Date
                        </label>
                      </div>

                      <div className="common-datepick-layout">
                        <DatePicker
                          selected={filterParams.startDate}
                          placeholderText="Start Date"
                          inputStyle={{ textAlign: "center" }}
                          id={filterParams.startDate}
                          minDate={capaMinDate}
                          onChange={(jsDate) =>
                            this.manageDataGatheringParams("filterParams", {
                              startDate: jsDate,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className=" w-50">
                      <div className="with-mic ">
                        <label className="slds-form-element__label">
                          End Date
                        </label>
                      </div>

                      <div className="common-datepick-layout">
                        <DatePicker
                          selected={filterParams.endDate}
                          placeholderText="End Date"
                          inputStyle={{ textAlign: "center" }}
                          id={filterParams.endDate}
                          minDate={filterParams.startDate}
                          onChange={(jsDate) =>
                            this.manageDataGatheringParams("filterParams", {
                              endDate: jsDate,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="with-mic my-3" >
                  <h6>
                    Corrective Action Proposed By
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>
                <div className="common-dropdown-layout">


                  <select
                    className="form-control1"
                    id={this.state.filterParams.recommendation_by}
                    value={this.state.filterParams.recommendation_by}
                    onChange={(e) => this.manageFilterParams('recommendation_by', e.target.value)}
                  >
                    <option value={""} disabled>Choose Option</option>
                    {recommendedby?.map((item, index) =>
                    (
                      <option key={index} value={item.recommended_id}  >{item.recommendedBy}</option>

                    )
                    )}

                  </select>


                </div> */}

                  {/* <div className="with-mic my-3">
                  <h6>
                    Responsible Person
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div className="common-dropdown-layout">


                  <select
                    className="form-control1"
                    id={this.state.filterParams.responsible_person_id}
                    value={this.state.filterParams.responsible_person_id}
                    onChange={(e) => this.manageFilterParams('responsible_person_id', e.target.value)}
                  >
                    <option value={""} disabled>Choose Option</option>
                    {this.state.responsible_person?.map((item, index) =>
                    (
                      <option key={index} value={item.person_id}  >{item.person}</option>

                    )
                    )}

                  </select>


                </div> */}

                  {/* <div className="with-mic my-3">
                  <h6>
                    Target Date
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div className="d-flex common-datepick-layout">
                  <div className="w-50">
                    <DatePicker
                      placeholderText="Start Date"
                      inputStyle={{ textAlign: 'center' }}
                      className="mx-2"
                      id={this.state.filterParams.startDate}
                      selected={this.state.filterParams.startDate}
                      onChange={(jsDate) => this.manageFilterParams('startDate', jsDate)}
                    />
                  </div>
                  <div className="w-50 mx-3">
                    <DatePicker
                      placeholderText="End Date"
                      inputStyle={{ textAlign: 'center' }}
                      className="mx-2"
                      id={this.state.filterParams.endDate}
                      minDate={this.state.filterParams.startDate}
                      selected={this.state.filterParams.endDate}
                      onChange={(jsDate) => this.manageFilterParams('endDate', jsDate)}
                    />
                  </div>


                </div> */}
                  {/* 
                <div className="with-mic my-3">
                  <h6>
                    Priority
                    <span className="inputErrorMsg">&nbsp;*</span>
                  </h6>
                </div>

                <div
                  className="control1 common-check-box-layout d-flex"
                >
                  <input type="checkbox" id="Priority" checked={this.state.extremeSelect} onChange={() => this.FilterData(this.state.extremeSelect, 4)} name="Priority1" value="Extreme" />
                  <label htmlFor="Priority1" className="mr-3"> Extreme</label>
                  <input type="checkbox" id="Priority" checked={this.state.highSelect} onChange={() => this.FilterData(this.state.highSelect, 3)} name="Priority2" value="High" />
                  <label htmlFor="Priority2"> High</label>
                  <br />
                  <input type="checkbox" className="ml-3" id="Priority" checked={this.state.mediumSelect} onChange={() => this.FilterData(this.state.mediumSelect, 2)} name="Priority3" value="Medium" />
                  <label htmlFor="Priority3" className="mr-3"> Medium</label>
                  <input type="checkbox" id="Priority" checked={this.state.lowSelect} onChange={() => this.FilterData(this.state.lowSelect, 1)} name="Priority4" value="Low" />
                  <label htmlFor="Priority4"> Low</label>

                </div> */}

                  <div className="with-mic ">
                    <label className="slds-form-element__label">Priority</label>
                  </div>
                  <div className="d-flex">
                    <Checkbox
                      assistiveText={{
                        label: "Extreme",
                      }}
                      labels={{
                        label: "Critical",
                      }}
                      id="Priority"
                      checked={this.state.extremeSelect}
                      onChange={() =>
                        this.FilterData(this.state.extremeSelect, 4)
                      }
                      name="Priority1"
                      value="Extreme"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "High",
                      }}
                      labels={{
                        label: "Urgent",
                      }}
                      id="High"
                      checked={this.state.highSelect}
                      onChange={() => this.FilterData(this.state.highSelect, 3)}
                      name="Priority2"
                      value="High"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "Medium",
                      }}
                      labels={{
                        label: "Important",
                      }}
                      id="Medium"
                      checked={this.state.mediumSelect}
                      onChange={() =>
                        this.FilterData(this.state.mediumSelect, 2)
                      }
                      name="Priority3"
                      value="High"
                    />
                    <Checkbox
                      assistiveText={{
                        label: "Low",
                      }}
                      labels={{
                        label: "Normal",
                      }}
                      id="Low"
                      checked={this.state.lowSelect}
                      onChange={() => this.FilterData(this.state.lowSelect, 1)}
                      name="Priority3"
                      value="Low"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <SLDModal
            disableClose
            footer={[
              <SLDButton
                key="promptBtn1"
                label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
                onClick={() =>
                  this.debounceFunc(() => {
                    this.manageFactors(this.state.rootfactorParams);
                  })
                }
              />,
              <SLDButton
                key="promptBtn"
                label="No"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.setState({
                      rootfactorParams: {},
                    });
                  })
                }
                disabled={this.state.isFactorLoading}
              />,
            ]}
            isOpen={Object.keys(this.state.rootfactorParams).length}
            contentClassName="text-center"
            prompt="error"
            size="small"
            title={
              <span className=" font-18 font-weight-500 text-center w-100">
                Delete Record
              </span>
            }
          >
            <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
              Do you really want to delete this?
            </div>
          </SLDModal>
          <SLDModal
            disableClose
            footer={[
              <SLDButton
                key="promptBtn1"
                label={deleteParams.isLoading ? "Loading ..." : "Yes"}
                onClick={() =>
                  this.debounceFunc(() => {
                    this.deleteRecords();
                  })
                }
              />,
              <SLDButton
                key="promptBtn"
                label="No"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.manageDataGatheringParams(
                      "",
                      {
                        isDeleteEnable: false,
                      },
                      false,
                      "deleteParams"
                    );
                  })
                }
                disabled={this.state.isFactorLoading}
              />,
            ]}
            isOpen={deleteParams.isDeleteEnable}
            contentClassName="text-center"
            prompt="error"
            size="small"
            title={
              <span className=" font-18 font-weight-500 text-center w-100">
                Delete Record
              </span>
            }
          >
            <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
              Do you really want to delete this?
            </div>
          </SLDModal>
          <SLDModal
            disableClose
            footer={[
              <SLDButton
                key="promptBtn"
                label="Close"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.setState({
                      docUrl: "",
                    });
                  })
                }
              />,
            ]}
            isOpen={this.state.docUrl !== ""}
            contentClassName="text-center"
            size="small"
            title={
              <span className=" font-18 font-weight-500 text-center w-100">
                Document View
              </span>
            }
          >
            <embed
              src={`${this.state.docUrl}#toolbar=0`}
              // frameborder="0"
              frameBorder="0"
              style={{
                width: "100%",
                height: "20vw",
                paddingBottom: "5%",
              }}
              controls
              controlsList="nodownload"
            />
          </SLDModal>
          <Modal
            centered
            show={this.state.activeAnalysisItem !== null && isApproveEnabled}
            className="submit-modal"
          >
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-black-1">
                    {" "}
                    {this.state.activeModule}{" "}
                  </span>
                </div>
              </div>
              <div className="modal-controls">
                <button
                  onClick={() =>
                    this.setState({
                      activeAnalysisItem: null,
                      activeModule: "",
                    })
                  }
                >
                  Close
                </button>

                <button
                  type="button"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.save5MAnalysis(
                        this.state.activeAnalysisItem,
                        this.state.analysisData
                      );
                    })
                  }
                >
                  Submit
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex space-between mx-5">
                <p className="common-black-1 font-16 mb-0 font-weight-500">
                  Selected
                </p>
                <p className="common-black-1 font-16 mb-0 font-weight-500">
                  Unselected
                </p>
              </div>
              <hr />
              <div className="d-flex space-between ">
                <div>
                  {this.getData(
                    iraDetailStep3.ira_5M_details,
                    this.state.activeAnalysisItem
                  )?.map((item, index) => (
                    <div
                      key={index}
                      className="my-2 common-check-box-layout d-flex space-between"
                    >
                      <div>
                        <input type="checkbox" checked />
                        <label htmlFor={item.factor}>{item.factor}</label>
                      </div>
                      <div>
                        <img
                          src={require("assets/sls-icons/V2. New Development/delete.svg")}
                          size="44"
                          className=" svg-icons cursor-pointer ml-2 "
                          aria-hidden="true"
                          onClick={() =>
                            this.debounceFunc(() => {
                              this.setState({
                                factorParams: {
                                  sub_factor_id: item.factor_id,
                                  five_m_analysis_type: item?.analysis_type,
                                },
                              });
                            })
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {this.state.analysisData?.map((item, index) => (
                    <div
                      key={index}
                      className="my-2 common-check-box-layout d-flex space-between"
                    >
                      <div>
                        <input
                          type="checkbox"
                          checked={item?.isActive}
                          onChange={() => {
                            this.manage5mPayload(item.factor_id);
                          }}
                        />
                        <label htmlFor={item.factor_name}>
                          {item.factor_name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.iraId}
            moduleType={"ira"}
            onAddNewUsers={this._addUsersToList}
            project_id={this.state.iraBasicInfo.project_id}
            service_id={this.state.iraBasicInfo.service_id}
            showInvitedUser={false}
            // project_id={20}
            // service_id={9}
          />
        ) : null}
          <Modal
            centered
            show={this.state.toggleStep2}
            className="submit-modal"
          >
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-black-1">
                    {" "}
                    {this.state.step2Type}{" "}
                  </span>
                </div>
              </div>
              <div className="modal-controls">
                <button onClick={this.hideModal}>Close</button>

                <button
                  type="button"
                  //  onClick={this.handleContrubutingSubmit}
                  disabled={this.state.isFactorLoading}
                  onClick={(e) =>
                    this.debounceFunc(() => {
                      if (e.detail == 1) {
                        this._InProgressFactorAddList(
                          this.state.step2Type === "Contributing Factor"
                            ? 1
                            : this.state.step2Type === "Immediate Factor"
                            ? 2
                            : this.state.RCAFList
                            ? 3
                            : ""
                        );
                      }
                    })
                  }
                >
                  Submit
                </button>
              </div>
            </Modal.Header>

            <div>
              {this.state.step2Type === "Contributing Factor" ? (
                <Modal.Body>
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {
                        this.state.CFList &&
                          this.state.CFList.factors_list.map((item, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onChange={this.handleCheckboxChange}
                                id={item.factor_id}
                                checked={item.checked}
                              />
                              <label htmlFor={item.factor_name}>
                                {item.factor_name}
                              </label>
                            </div>
                          ))
                        // :<h3>No data</h3>
                      }
                    </form>
                  </div>
                </Modal.Body>
              ) : null}

              {this.state.step2Type === "Immediate Factor" ? (
                <Modal.Body>
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {this.state.IFList &&
                        this.state.IFList.factors_list.map((item, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              onChange={this.handleCheckboxChangeIF}
                              id={item.factor_id}
                              checked={item.checked}
                            />
                            <label htmlFor={item.factor_name}>
                              {item.factor_name}
                            </label>
                          </div>
                        ))}
                    </form>
                  </div>
                </Modal.Body>
              ) : null}

              {this.state.step2Type === "Root Cause Analysis" ? (
                <Modal.Body>
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {this.state.RCAFList &&
                        this.state.RCAFList.factors_list.map((item, index) => (
                          <div key={index} className="d-flex space-between">
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleCheckboxChangeRAC}
                                id={item.factor_id}
                                checked={item.checked}
                              />
                              <label htmlFor={item.factor_name}>
                                {item.factor_name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </form>
                  </div>
                </Modal.Body>
              ) : null}
            </div>
          </Modal>
          <SLDModal
            disableClose
            footer={[
              <SLDButton
                key="promptBtn1"
                label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
                onClick={() =>
                  this.debounceFunc(() => {
                    this.deleteAnalyisiData();
                  })
                }
              />,
              <SLDButton
                key="promptBtn"
                label="No"
                onClick={() => {
                  this.setState({
                    factorParams: {},
                  });
                }}
                disabled={this.state.isFactorLoading}
              />,
            ]}
            isOpen={Object.keys(this.state.factorParams).length}
            contentClassName="text-center"
            prompt="error"
            size="small"
            title={
              <span className=" font-18 font-weight-500 text-center w-100">
                Delete Record
              </span>
            }
          >
            <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
              Do you really want to delete this?
            </div>
          </SLDModal>
        </>
      </div>
    );
  }
}

export default withRouter(IRADetail);

export const ProgressBarWrapper = ({ value }) => {
  return (
    <div className="output-text d-flex align-items-center">
      <div className="w-100 rounded-pill" style={{ height: "0.5vw" }}>
        <ProgressBar className="rounded-pill h-100" now={value} label={""} />
      </div>
      <div>
        {" "}
        <span className="font-weight-500 font-18 ml-3">{value + "%"} </span>
      </div>
    </div>
  );
};

export const Spinner = () => {
  return (
    <div class="demo-only m-2" style={{ position: "relative" }}>
      <div class="slds-spinner_container">
        <div
          role="status"
          class="slds-spinner slds-spinner_small slds-spinner_brand"
        >
          <span class="slds-assistive-text">Loading</span>
          <div class="slds-spinner__dot-a"></div>
          <div class="slds-spinner__dot-b"></div>
        </div>
      </div>
    </div>
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
