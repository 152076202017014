import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Row, Col } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
function MyDropzone({
  type,
  uploadFile,
  disabledPlaceHolder = false,
  clearDropZone = false,
}) {
  const [accept, setAccept] = useState("");
  useEffect(() => {
    if (type == 1) {
      setAccept(".jpg, .jpeg, .png");
    } else if (type == 2) {
      setAccept(".mp4, .mkv, .wav, .omv");
    } else if (type == 3 || type == 4 || type == 7) {
      setAccept(".pdf");
    } else if (type == 5) {
      setAccept(".jpg, .jpeg, .png, .mp4, .mkv, .wav, .omv");
    } else if (type == 6) {
      setAccept(".pdf, .doc, .docx, .xls, .xlsx");
    } else if (type == 8) {
      setAccept(
        ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .mp4, .mkv, .wav, .omv, .jpg, .jpeg, .png, .mp4, .mkv, .wav, .omv "
      );
    } else if (type == 9) {
      setAccept(".jpg, .jpeg, .png, .pdf");
    } else if (type == 10) {
      setAccept(".doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .pdf ");
    }
  }, [type]);

  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      accept,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        acceptedFiles.map((file) => (console.log(file), uploadFile(file)));
      },
    });

  const files1 = acceptedFiles.map((file) =>
    type == 3 || type == 4 || type == 7 || type == 6 || type == 8 ? (
      <li key={file.path}>{file.path}</li>
    ) : null
  );
  const remove = (file) => {
    // make a var for the new array
    acceptedFiles.splice(files1, 1); // remove the file from the array
  };
  const thumbs = files.map((file) => (
    <>
      {file.type.includes("video") && (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <video src={file.preview} style={img} />
          </div>
        </div>
      )}
      {file.type.includes("image") && (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img src={file.preview} style={img} alt={file.path} />
          </div>
        </div>
      )}
    </>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (files.length && clearDropZone) {
      remove();
      setFiles([]);
    }
  }, [clearDropZone]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map((e) => (
      <li key={e.code} className="m-2">
        {e.message}
      </li>
    ))
  );

  return (
    <section
      className="dropzone clide-v2-border-primary-color container-drop dott p-4 rounded w-100"
      style={{ borderStyle: "dotted" }}
    >
      <Row>
        <Col sm={acceptedFiles.length ? 9 : 12}>
          <div {...getRootProps({ className: "dropzone " })}>
            <input {...getInputProps()} />
            <div className="d-flex-center flex-column">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6668 21.333V10.4663L11.2002 13.933L9.3335 11.9997L16.0002 5.33301L22.6668 11.9997L20.8002 13.933L17.3335 10.4663V21.333H14.6668ZM8.00016 26.6663C7.26683 26.6663 6.63927 26.4055 6.1175 25.8837C5.59572 25.3619 5.33438 24.7339 5.3335 23.9997V19.9997H8.00016V23.9997H24.0002V19.9997H26.6668V23.9997C26.6668 24.733 26.4059 25.361 25.8842 25.8837C25.3624 26.4063 24.7344 26.6672 24.0002 26.6663H8.00016Z"
                  fill="#FF6347"
                />
              </svg>

              <p className={"clide-v2-title mb-1 ml-3 ml-6" + acceptedFiles.length ? " text-center text-wrap" : ""}>
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
            {!disabledPlaceHolder && (
              <div className="d-flex-center">
                {type == 1 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">
                    (Only *.jpg, *.jpeg and *.png images will be accepted)
                  </em>
                ) : null}
                {type == 2 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">
                    (Only *.mp4, *.mkv, *.wav and *.omv videos will be accepted)
                  </em>
                ) : null}
                {type == 3 || type == 4 || type == 7 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">(Only *.pdf documents will be accepted)</em>
                ) : null}
                {type == 5 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">
                    (Only *.jpg, *.jpeg, *.png, *.mp4, *.mkv, *.wav and *.omv
                    videos will be accepted)
                  </em>
                ) : null}
                {type == 6 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">
                    (Only *.pdf, *.doc, *.docx, *xls, *xlsx documents will be
                    accepted)
                  </em>
                ) : null}
                {type == 10 ? (
                  <em className="clide-v2-fs-6 clide-v2-primary-color">
                    (Only .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .pdf
                    documents will be accepted)
                  </em>
                ) : null}
              </div>
            )}
          </div>
        </Col>
        {acceptedFiles.length ? (
          <Col sm={acceptedFiles.length ? 3 : 0}>
            <Scrollbars style={{ height: "80px" }} renderView={(props) => (
                    <div
                      {...props}
                      style={{
                        paddingRight: "15px",
                        ...props.style,
                        overflowX: "hidden",
                      }}
                    />
                  )}>
              <aside>
                <ul style={{ textAlign: "start" }}>{files1}</ul>
                <ul style={{ textAlign: "start", color: "red" }}>
                  {fileRejectionItems}
                </ul>
              </aside>
              {type != 3 && type != 4 ? (
                <aside style={thumbsContainer}>{thumbs}</aside>
              ) : null}
            </Scrollbars>
          </Col>
        ) : null}
      </Row>
    </section>
  );
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  // width: 100,
  height: 100,
  width: "80%",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

export default MyDropzone;
