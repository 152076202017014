const PageThree = ( data, logo ) => {
  
  return `<table cellpadding="15" cellspacing="0" width="800" style="margin: 0 auto 10px auto;font-family: arial;height: 100vh;font-size: 14px;position:relative" class="full-table">
<tr>
<td valign="top" class="inner-table">
<table width="100%">
  <tr>
    <td valign="middle" style="height: 100px">
      <h3 style="text-transform: uppercase; font-size: 18px; text-align: center; font-weight: bold">Incident Investigation Report</h3>
    </td>
    <td valign="middle" style="text-align: right;">
    </td>
      </tr>
    </table>
    <span style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Activity : </span><span style="font-size: 16px">${data.subject || ''}</span>  
    <hr />
    <br />
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Incident Evidences</h4>
    <br />
    <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
      <tr>
        <td style="text-align: center;">1</td>
        <td>Service</td>
        <td>${data.incident_info?.service || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">2</td>
        <td>Project</td>
        <td>${data.incident_info?.project || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">3</td>
        <td>Department</td>
        <td>${data.incident_info?.department || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">4</td>
        <td>Contractor</td>
        <td>${data.incident_info?.contractor || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">5</td>
        <td>Zone</td>
        <td>${data.incident_info?.zone || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">6</td>
        <td >Location</td>
        <td>${data.incident_info?.location || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">7</td>
        <td>Incident Date</td>
        <td> ${data.incident_info?.incident_date || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center">8</td>
        <td>Incident Time</td>
        <td>${data.incident_info?.incident_time || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">9</td>
        <td>Target Date</td>
        <td> ${data.incident_info?.target_date || 'N/A'}</td>
       
      </tr>
    </table>
    <br />
    <br />
    <h4 style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Reporting Info</h4>
    <br />
    <table cellpadding="6" cellspacing="0" border="1" bordercolor="#e2e2e2" width="100%">
      <tr>
        <td style="text-align: center;">1</td>
        <td>Reported by</td>
        <td>${data.reporting_info?.reported_by || 'N/A'}</td>
      </tr>
      <tr>
        <td style="text-align: center;">2</td>
        <td>Reported Date</td>
        <td> ${data.reporting_info?.reported_date || 'N/A'}</td>
        
      </tr>
      <tr>
        <td style="text-align: center;">3</td>
        <td>Assign to</td>
        <td>${data.reporting_info?.assign_to || 'N/A'}</td>
      </tr>
    </table>
    <br />
    <br />
    <span style="font-size: 16px;margin-bottom: 5px;font-weight:bold">Observation</span>
    <span style="font-size: 16px;margin-bottom: 5px;">(Incident description) </span>
    <hr />
    <p style="margin-top: 0;line-height: 20px">${data.observation || ''}</p>

    <span class="page-number" />
  </td>
</tr>


</table>`;
}

export default PageThree;
